import { defaultExporter, DataProvider } from "react-admin";
import { get } from "lodash-es";
import { DateTime } from "luxon";
import { stripTypenames } from "./strip-typenames.ts";

function transformValues(
	value: Record<string, unknown>,
): Record<string, unknown> {
	return Object.fromEntries(
		Object.entries(value).map(([k, v]) => {
			if (v && typeof v === "object") {
				return [k, transformValues(v as Record<string, unknown>)];
			}
			// react-admin doesn't put 0 in csv output
			if (v === 0) {
				return [k, v.toString()];
			}
			if (typeof v !== "string") {
				return [k, v];
			}
			const date = DateTime.fromISO(v);
			if (date.isValid) {
				// Other admin date displays use browser's locale. Eyal requested Sydney for CSV
				return [k, date.toLocal().toFormat("yyyy-MM-dd HH:mm:ss.SSS")];
			}
			return [k, v];
		}),
	);
}

type ListExporterOptions = {
	/** @deprecated Use export query instead and just include everything */
	readonly fields?: readonly string[];
};

// TODO: If successful in removing all uses of fields, then have a static instance here
function createListExporter(options?: ListExporterOptions) {
	return async function listExporter<T extends { __typename?: string }>(
		records: readonly T[],
		fetchRelatedRecords: (
			data: any,
			field: string,
			resource: string,
		) => Promise<any>,
		dataProvider: DataProvider,
		resource?: string,
	) {
		let updated = records.map(stripTypenames).map(transformValues);
		if (options?.fields) {
			const { fields } = options;
			updated = updated.map((r) =>
				Object.fromEntries(fields.map((f) => [f, get(r, f)])),
			);
		}
		await defaultExporter(updated, fetchRelatedRecords, dataProvider, resource);
	};
}

export default createListExporter;
