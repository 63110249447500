import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "adminPicToBrickCategoryList",
		itemFragment: gql`
			fragment listPicToBrickCategoryItem on AdminPicToBrickCategory {
				__typename
				id
				name
			}
		`,
	},
	GET_ONE: {
		name: "adminPicToBrickCategoryById",
		itemFragment: gql`
			fragment picToBrickCategoryItem on AdminPicToBrickCategory {
				__typename
				id
				id
				name
			}
		`,
	},
	CREATE: {
		name: "adminCreatePicToBrickCategory",
	},
	UPDATE: {
		name: "adminUpdatePicToBrickCategory",
	},
	DELETE: {
		name: "adminDeletePicToBrickCategory",
	},
} as GraphQLResourceDefinition;
