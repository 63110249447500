import React from "react";
import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	Button,
	List,
	ListItem,
} from "@material-ui/core";
import { usePermissions } from "ra-core";
import { useShopifyUrl } from "~/hooks/shopify-url.tsx";

function Dashboard() {
	const { permissions } = usePermissions();
	const shopifyUrl = useShopifyUrl();
	const links = [
		{ href: "https://dashboard.stripe.com/", label: "Stripe Dashboard" },
		{ href: `${shopifyUrl}/admin`, label: "Shopify Admin" },
		{ href: "https://admin.mailchimp.com/", label: "Mailchimp Admin" },
	];

	return (
		<Card>
			<CardHeader title="Brick.me admin system" />
			{permissions && permissions.includes("*") && (
				<CardContent>
					<Typography>See also:</Typography>
					<List>
						{links.map((link) => (
							<ListItem key={link.href}>
								<Button
									color="secondary"
									href={link.href}
									target="_blank"
									rel="noreferrer noopener"
								>
									{link.label}
								</Button>
							</ListItem>
						))}
					</List>
				</CardContent>
			)}
		</Card>
	);
}

export { Dashboard };
