import { gql } from "@apollo/client";
import { InventoryState } from "./inventory-state.ts";
import fullInventoryStateFragment from "./full-inventory-state-fragment.ts";

export type GetSaleInventoryStateQuery = {
	readonly saleInventoryState: InventoryState;
};

export type GetSaleInventoryStateVariables = {
	readonly from?: string;
	readonly to?: string;
};

export default gql`
	query getSaleInventoryStateQuery($from: DateTime, $to: DateTime) {
		saleInventoryState(from: $from, to: $to) {
			...fullInventoryState
		}
	}
	${fullInventoryStateFragment}
`;
