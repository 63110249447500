import { gql } from "@apollo/client";

const mutation = gql`
	mutation approveBulkInventoryScan(
		$id: ID!
		$applyType: ApprovebulkinventoryscanApplytype!
		$applyAt: DateTime!
		$adjustments: [ApprovebulkinventoryscanAdjustments!]!
	) {
		approveBulkInventoryScan(
			id: $id
			applyType: $applyType
			applyAt: $applyAt
			adjustments: $adjustments
		) {
			__typename
		}
	}
`;

type ApproveBulkInventoryScanMutation = unknown;

type ApproveBulkInventoryScanMutationVariables = {
	readonly applyType: "count" | "add" | "remove";
	readonly id: string;
	readonly applyAt: string;
	readonly adjustments: readonly {
		readonly barcode: string;
		readonly amount: number;
	}[];
};

export type {
	ApproveBulkInventoryScanMutation,
	ApproveBulkInventoryScanMutationVariables,
};
export default mutation;
