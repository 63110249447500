import { padStart } from "lodash-es";

function formatMoneyRounded(dollarAmount: string | number): string {
	const dollarNum =
		typeof dollarAmount === "number" ? dollarAmount : parseFloat(dollarAmount);
	const cents = Math.round(dollarNum * 100.0);
	const absCents = Math.abs(cents);
	const sign = absCents > 0 ? cents / absCents : 1;
	const wholeCents = absCents % 100;
	const dollars = Math.floor(absCents / 100);
	return `${sign === -1 ? "-" : ""}$${dollars.toLocaleString()}.${padStart(
		wholeCents.toString(),
		2,
		"0",
	)}`;
}

function formatMoneyFullPrecision(dollarAmount: string): string {
	return `$${dollarAmount}`;
}

export { formatMoneyFullPrecision, formatMoneyRounded };
