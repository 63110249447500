import { useQuery, QueryHookOptions } from "@apollo/client/react";
import { useNotify } from "react-admin";
import { DateTime } from "luxon";
import getInventoryStateQuery, {
	GetInventoryStateQuery,
	GetInventoryStateVariables,
} from "~/api/get-inventory-state-query.ts";
import { InventoryState } from "~/api/inventory-state.ts";

function useInventoryState(
	date?: DateTime,
	options?: Omit<
		QueryHookOptions<GetInventoryStateQuery, GetInventoryStateVariables>,
		"variables"
	>,
): { loading: boolean; inventoryState?: InventoryState } {
	const notify = useNotify();
	const { data, loading } = useQuery<
		GetInventoryStateQuery,
		GetInventoryStateVariables
	>(getInventoryStateQuery, {
		variables: { date: date?.toISO() },
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
		...options,
	});
	return { inventoryState: data?.inventoryState, loading };
}

export default useInventoryState;
