import { Record } from "react-admin";
import { formatMoneyRounded, formatMoneyFullPrecision } from "~/utils/money.ts";

function formatInventoryItemRoundedValue(
	record: Record,
	value: number | string,
) {
	if (record.type === "cost-price") {
		return formatMoneyRounded(value.toString());
	}
	return value.toLocaleString();
}

function formatInventoryItemFullValue(record: Record, value: number | string) {
	if (record.type === "cost-price") {
		return formatMoneyFullPrecision(value.toString());
	}
	return value.toLocaleString();
}

export { formatInventoryItemFullValue, formatInventoryItemRoundedValue };
