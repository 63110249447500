import cartProjects from "~/features/cart-project/resource.ts";
import orderedProjects from "~/features/ordered-project/resource.ts";
import savedProjects from "~/features/saved-project/resource.ts";
import users from "~/features/user/resource.ts";
import paletteColours from "~/features/palette-colour/resource.ts";
import pricingModel from "~/features/pricing-model/resource.ts";
import brickArtDesigns from "~/features/brick-art-design/resource.ts";
import reports from "~/features/report/resource.ts";
import premadeKitCodes from "~/features/premade-kits/code-resource.ts";
import premadeKitCodeGenerations from "~/features/premade-kits/code-generation-resource.ts";
import inventoryState from "~/features/inventory/inventory-state-resource.ts";
import inventoryItems from "~/features/inventory/resource.ts";
import inventoryItemsLegacy from "~/features/inventory-legacy/resource.ts";
import picsToBrick from "~/features/pic-to-brick/pic-resource.ts";
import picToBrickCategories from "~/features/pic-to-brick/category-resource.ts";
import bulkInventoryItems from "~/features/inventory-bulk/bulk-inventory-item-resource.ts";
import bulkInventoryCounts from "~/features/inventory-bulk/bulk-inventory-scan-resource.ts";
// import GraphQLResourceDefinition from "./GraphQLResourceDefinition.ts";

export default {
	cartProjects,
	orderedProjects,
	savedProjects,
	users,
	brickArtDesigns,
	picToBrickCategories,
	picsToBrick,
	paletteColours,
	pricingModel,
	inventoryItems,
	inventoryItemsLegacy,
	inventoryState,
	reports,
	premadeKitCodeGenerations,
	premadeKitCodes,
	bulkInventoryItems,
	bulkInventoryCounts,
} as Record<string, any>; // GraphQLResourceDefinition>;
