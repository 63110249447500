import React, { ReactElement } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Record as RaRecord } from "ra-core";
import {
	PublicFieldProps,
	InjectedFieldProps,
} from "ra-ui-materialui/lib/field/types";
import { mapValues } from "lodash-es";

type LinkToRelatedProps<RecordType extends RaRecord = RaRecord> =
	PublicFieldProps &
		InjectedFieldProps<RecordType> & {
			readonly pathname: string;
			readonly filter: Record<string, (record: RecordType) => string>;
			readonly children: string;
		};

function LinkToRelated<RecordType extends RaRecord = RaRecord>({
	record,
	pathname,
	filter,
	children,
}: LinkToRelatedProps<RecordType>): ReactElement | null {
	return record ? (
		<Button
			color="primary"
			component={Link}
			to={{
				pathname,
				search: `filter=${JSON.stringify(
					mapValues(filter, (getter) => encodeURIComponent(getter(record))),
				)}`,
			}}
		>
			{children}
		</Button>
	) : null;
}

export default LinkToRelated;
