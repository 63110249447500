/* eslint-disable */
import React, {
	FunctionComponent,
	Children,
	cloneElement,
	isValidElement,
	ReactElement,
	useState,
} from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import { clsx } from "clsx";
import { useInput, useTranslate, InputProps } from "ra-core";
import FileInputPreview from "ra-ui-materialui/lib/input/FileInputPreview";
// import sanitizeRestProps from "ra-ui-materialui/lib/input/sanitizeRestProps";
import InputHelperText from "ra-ui-materialui/lib/input/InputHelperText";
import Labeled from "ra-ui-materialui/lib/input/Labeled";
import { useCallback } from "react";
import { useApolloClient } from "@apollo/client/react";
import availablePresignedMediaUrlQuery, {
	AvailablePresignedMediaUrl,
	AvailablePresignedMediaUrlVariables,
} from "~/api/available-presigned-media-url.ts";
import { useMediaBaseUrl } from "~/hooks/media-base-url.tsx";

function sanitizeRestProps(props: any) {
	return props;
}

const useStyles = makeStyles(
	(theme) => ({
		dropZone: {
			background: theme.palette.background.default,
			cursor: "pointer",
			padding: theme.spacing(1),
			textAlign: "center",
			color: theme.palette.getContrastText(theme.palette.background.default),
		},
		preview: {},
		removeButton: {},
		root: { width: "100%" },
	}),
	{ name: "RaFileInput" },
);

export type SignedUploadInputProps = {
	readonly directory: string;
	accept?: string;
	// labelMultiple?: string;
	labelSingle?: string;
	maxSize?: number;
	minSize?: number;
	// multiple?: boolean;
};

export type SignedUploadInputOptions = DropzoneOptions & {
	inputProps?: any;
	onRemove?: Function;
};

const SignedUploadInput: FunctionComponent<
	SignedUploadInputProps & InputProps<SignedUploadInputOptions>
> = (props) => {
	const {
		directory,
		accept,
		children,
		className,
		classes: classesOverride,
		format,
		helperText,
		label,
		// labelMultiple = 'ra.input.file.upload_several',
		labelSingle = "ra.input.file.upload_single",
		maxSize,
		minSize,
		// multiple = false,
		options: {
			inputProps: inputPropsOptions,
			...options
		} = {} as SignedUploadInputOptions,
		parse,
		placeholder,
		resource,
		source,
		validate,
		...rest
	} = props;
	const translate = useTranslate();
	const classes = useStyles(props);

	// turn a browser dropped file structure into expected structure
	const transformFile = useCallback(
		(file: any) => {
			if (!(file instanceof File)) {
				return file;
			}

			const { source, title } = (Children.only(children) as ReactElement<any>)
				.props;

			const preview = URL.createObjectURL(file);
			const transformedFile = {
				rawFile: file,
				[source]: preview,
			};

			if (title) {
				transformedFile[title] = file.name;
			}

			return transformedFile;
		},
		[children],
	);

	const transformFiles = useCallback(
		(files: any[]) => {
			if (!files) {
				// return multiple ? [] : null;
				return null;
			}

			if (Array.isArray(files)) {
				return files.map(transformFile);
			}

			return transformFile(files);
		},
		[transformFile],
	);

	const {
		id,
		input: { onChange, value, ...inputProps },
		meta,
		isRequired,
	} = useInput({
		format: format || transformFiles,
		parse: parse || transformFiles,
		source,
		type: "file",
		validate,
		...rest,
	});
	const { touched, error } = meta;
	const apolloClient = useApolloClient();

	const [isWorking, setWorking] = useState(false);
	const onDrop = useCallback(
		async (newFiles: File[]) => {
			if (newFiles.length === 0) {
				return;
			}

			setWorking(true);
			try {
				const newFile = newFiles[0];
				const result = await apolloClient.query<
					AvailablePresignedMediaUrl,
					AvailablePresignedMediaUrlVariables
				>({
					query: availablePresignedMediaUrlQuery,
					variables: {
						directory,
						preferredFileName: newFile.name,
						contentType: newFile.type,
					},
					fetchPolicy: "network-only",
				});
				const presigned = result.data.availablePresignedMediaUrl;
				const response = await fetch(presigned.url, {
					method: "PUT",
					body: newFile,
				});
				setWorking(false);
				if (!response.ok) {
					throw new Error(`${response.status}: ${response.statusText}`);
				}
				onChange(presigned.key);
			} catch (e) {
				setWorking(false);
				throw e;
			}

			// const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];
			// const updatedFiles = [...newFiles];
			//
			// // if (multiple) {
			// //   onChange(updatedFiles);
			// // } else {
			//   onChange(updatedFiles[0]);
			// // }
		},
		[onChange, directory, apolloClient],
	);

	const onRemove = (file: any) => () => {
		// if (multiple) {
		//   const filteredFiles = files.filter(
		//     stateFile => !shallowEqual(stateFile, file)
		//   );
		//   onChange(filteredFiles as any);
		// } else {
		onChange(null);
		// }

		if (options.onRemove) {
			options.onRemove(file);
		}
	};

	const childrenElement = isValidElement(Children.only(children))
		? (Children.only(children) as ReactElement<any>)
		: undefined;

	const { getRootProps, getInputProps } = useDropzone({
		...options,
		accept,
		maxSize,
		minSize,
		// multiple,
		onDrop,
	});

	const mediaBaseUrl = useMediaBaseUrl();
	const files = (value ? (Array.isArray(value) ? value : [value]) : []).map(
		(name) => mediaBaseUrl + "/" + name,
	);

	return (
		<Labeled
			id={id}
			label={label}
			className={clsx(classes.root, className)}
			source={source}
			resource={resource}
			isRequired={isRequired}
			meta={meta}
			{...sanitizeRestProps(rest)}
		>
			<>
				<div
					data-testid="dropzone"
					className={classes.dropZone}
					{...getRootProps()}
				>
					<input
						id={id}
						{...getInputProps({
							...inputProps,
							...inputPropsOptions,
						})}
					/>
					{placeholder ? (
						placeholder /*: multiple ? (
            <p>{translate(labelMultiple)}</p>
          )*/
					) : (
						<p>{translate(labelSingle)}</p>
					)}
				</div>
				<FormHelperText>
					<InputHelperText
						touched={!!touched}
						error={error}
						helperText={helperText}
					/>
				</FormHelperText>
				{children && childrenElement && (
					<div className="previews">
						{isWorking && <div>Uploading...</div>}
						{files.map((file, index) => (
							<FileInputPreview
								key={index}
								file={file as any}
								onRemove={onRemove(file)}
								className={classes.removeButton}
							>
								{cloneElement(childrenElement, {
									record: file,
									className: classes.preview,
								})}
							</FileInputPreview>
						))}
					</div>
				)}
			</>
		</Labeled>
	);
};

// SignedUploadInput.propTypes = {
//   accept: PropTypes.string,
//   children: PropTypes.element,
//   classes: PropTypes.object,
//   className: PropTypes.string,
//   id: PropTypes.string,
//   isRequired: PropTypes.bool,
//   label: PropTypes.string,
//   labelMultiple: PropTypes.string,
//   labelSingle: PropTypes.string,
//   maxSize: PropTypes.number,
//   minSize: PropTypes.number,
//   multiple: PropTypes.bool,
//   options: PropTypes.object,
//   resource: PropTypes.string,
//   source: PropTypes.string,
//   placeholder: PropTypes.node,
// };

export default SignedUploadInput;
