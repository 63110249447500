import { gql } from "@apollo/client";

type PictureSummaryFragment = {
	readonly basePlateSize: number;
	readonly numberOfBasePlates: {
		readonly width: number;
		readonly height: number;
	};
	readonly buildNumberOfColours: number;

	readonly brightness: number;
	readonly contrast: number;
	readonly saturation: number;

	readonly enhanceDetailName: string;

	readonly enhanceFaces?: boolean;
	readonly fixFaceColours?: boolean;
	readonly removeBackground?: {
		readonly newColour: string;
	};
};

const pictureSummaryFragment = gql`
	fragment pictureSummary on PictureConfiguration {
		basePlateSize
		numberOfBasePlates {
			width
			height
		}
		buildNumberOfColours
		brightness
		contrast
		saturation
		enhanceDetailName
		enhanceFaces
		fixFaceColours
		removeBackground {
			newColour
		}
	}
`;

export type { PictureSummaryFragment };
export default pictureSummaryFragment;
