import { sum } from "lodash-es";
import { InventoryState, InventoryStateItem } from "~/api/inventory-state.ts";

function calculateItemTotalCost(item: InventoryStateItem): number {
	return sum(
		item.history.map(
			(h) =>
				h.quantity *
				(h.cost === undefined || h.cost === null ? 0 : parseFloat(h.cost)),
		),
	);
}

function calculateStateTotalCost(state: InventoryState): number {
	return sum(
		[
			state.paperA5,
			state.paperA4,
			state.paperLetter,
			state.paperA3,
			state.brickSeparators,
			state.smallFlatpackBox,
			state.packaging,
			...state.baseplates,
			...state.containers,
		].map(calculateItemTotalCost),
	);
}

export { calculateItemTotalCost, calculateStateTotalCost };
