// import writeXlsxFile from 'write-excel-file'
import { write as xlsxWrite, utils as xlsxUtils } from "xlsx";
import { range, reverse } from "lodash-es";
import {
	BaseInventoryStateItem,
	InventoryState,
	InventoryStateItem,
} from "~/api/inventory-state.ts";
import { Container, PaletteColour } from "./data.ts";
import { calculateStateTotalCost } from "./calculate-total-cost.ts";

const currencyFormat = '"$"#,##0.00;[Red]\\-"$"#,##0.00';
const intFormat = "#,##0";

function createHistoryItemQuantityCostPair(item: BaseInventoryStateItem) {
	return [
		{
			value: item.quantity,
			format: intFormat,
		},
		{
			value: item.quantity * (item.cost ? parseFloat(item.cost) : 0),
			format: currencyFormat,
		},
	];
}

const colCharacters = range("A".charCodeAt(0), "Z".charCodeAt(0)).map((i) =>
	String.fromCharCode(i),
);

function createHistoryLines(items: readonly InventoryStateItem[]) {
	const histories = items.map((p) => {
		const nonZeroHistories = p.history.filter((h) => h.quantity !== 0);
		if (nonZeroHistories.length === 0) {
			return [
				{
					cost: "0",
					quantity: 0,
				},
			];
		}
		return nonZeroHistories;
	});
	const maxHistories = Math.max(...histories.map((h) => h.length));
	return reverse(
		range(0, maxHistories).map((i) => {
			return histories.flatMap((historySet) => {
				const historyItem = historySet[i];
				return historyItem
					? createHistoryItemQuantityCostPair(historyItem)
					: [null, null];
			});
		}),
	);
}

function createPaperDataLines(state: InventoryState) {
	return [
		[
			{
				value: "Paper",
				fontWeight: "bold" as const,
			},
		],
		[
			{
				value: "A5",
				fontWeight: "bold" as const,
			},
			null,
			{
				value: "A4",
				fontWeight: "bold" as const,
			},
			null,
			{
				value: "A3",
				fontWeight: "bold" as const,
			},
			null,
			{
				value: "Letter",
				fontWeight: "bold" as const,
			},
		],
		...createHistoryLines([
			state.paperA5,
			state.paperA4,
			state.paperA3,
			state.paperLetter,
		]),
	];
}

function createSpreadsheet(
	colours: readonly PaletteColour[],
	containers: readonly Container[],
	state: InventoryState,
) {
	const headerRows = [
		[
			{
				value: "Colours",
				fontWeight: "bold" as const,
			},
			...containers.flatMap((c) => [
				{
					value: c.identifier,
					span: 2,
					fontWeight: "bold" as const,
				},
				null,
			]),
		],
		[
			{ value: "" },
			...containers.flatMap(() => [
				{
					value: "Quantity",
					fontWeight: "bold" as const,
				},
				{
					value: "Cost",
					fontWeight: "bold" as const,
				},
			]),
		],
	];

	const colourRows = colours.flatMap((colour) => {
		const colourLines = createHistoryLines(
			containers.map((c) => {
				const containerItem = state.containers.find(
					(sc) =>
						sc.containerId === c.identifier &&
						sc.colourId.toString() === colour.id.toString(),
				);
				if (!containerItem) {
					return {
						quantity: 0,
						cost: "0",
						history: [],
					};
				}
				return containerItem;
			}),
		);

		return colourLines.map((line, i) => [
			i === 0
				? {
						value: colour.id,
						fontWeight: "bold" as const,
					}
				: null,
			...line,
		]);
	});

	const data = [
		...headerRows,
		...colourRows,
		[],
		[],
		[
			{
				value: "Baseplates",
				fontWeight: "bold" as const,
				span: 3,
			},
		],
		state.baseplates.flatMap((b) => [
			{
				value: `${b.size}x${b.size}`,
				fontWeight: "bold" as const,
			},
			null,
		]),
		...createHistoryLines(state.baseplates),
		[],
		...createPaperDataLines(state),
		[],
		[
			{
				value: "Brick separators",
				fontWeight: "bold" as const,
			},
			null,
			{
				value: "Packaging",
				fontWeight: "bold" as const,
			},
			null,
			{
				value: "Small flatpack box",
				fontWeight: "bold" as const,
			},
			null,
		],
		...createHistoryLines([
			state.brickSeparators,
			state.packaging,
			state.smallFlatpackBox,
		]),
		[],
		[
			{
				value: "Total cost",
				fontWeight: "bold" as const,
			},
			{
				value: calculateStateTotalCost(state),
				format: currencyFormat,
			},
		],
	];

	const ws = xlsxUtils.aoa_to_sheet(
		data.map((r) => r.map((c) => c?.value)),
		{},
	);

	data.forEach((row, y) =>
		row.forEach((cell, x) => {
			if (typeof cell !== "object" || !cell) {
				return;
			}

			const cellName = `${colCharacters[x]}${y + 1}`;
			const sheetCell = ws[cellName];

			if ("fontWeight" in cell && cell.fontWeight === "bold") {
				// Only available in pro version :(
				// sheetCell.s = {
				//   ...sheetCell.s,
				//   font: {
				//     bold: true
				//   }
				// };
			}

			if ("format" in cell) {
				sheetCell.z = cell.format;
			}
		}),
	);

	const wb = xlsxUtils.book_new();
	xlsxUtils.book_append_sheet(wb, ws);
	const out = xlsxWrite(wb, {
		type: "buffer",
		Props: {
			Title: "Report",
			// Subject: string;
			Author: "Brick me admin",
			// Company?: string;
			// CreatedDate?: Date;
		},
	});

	//   const blob = await writeXlsxFile(data, {});
	//   const arrayBuffer = await (blob as Blob).arrayBuffer();
	//   return arrayBuffer;

	return out;
}

export default createSpreadsheet;
