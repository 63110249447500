import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";
import pictureSummaryFragment from "~/api/picture-summary-fragment.ts";
import userSummaryFragment from "~/api/user-summary-fragment.ts";
import {
	fullPictureConfigurationExportFragment,
	fullUserInfoExportFragment,
} from "~/utils/export-fragments.ts";

export default {
	GET_LIST: {
		name: "adminOrderedProjectList",
		useListAndCount: true,
		itemFragment: gql`
			fragment orderedProjectsList_item on OrderedProject {
				__typename
				id
				sourceType
				isArchived
				updatedAt
				mediumImageUrl
				shopifyEmailAddress
				shopifyOrderNumber
				user {
					emailAddress
				}
			}
		`,
	},
	GET_EXPORT: {
		name: "adminOrderedProjectList",
		useListAndCount: true,
		itemFragment: gql`
			fragment orderedProjectsExport_item on OrderedProject {
				__typename
				id
				isArchived
				sourceType
				updatedAt
				mediumImageUrl
				sourceImageUrl
				user {
					__typename
					id
					...fullUserInfoExport_user
				}
				picture {
					...fullPictureConfigurationExport_pictureConfiguration
				}
				instructionManualUrl
				shopifyAdminProductUrl
				shopifyOrderNumber
			}
			${fullUserInfoExportFragment}
			${fullPictureConfigurationExportFragment}
		`,
	},
	GET_ONE: {
		name: "adminOrderedProjectById",
		itemFragment: gql`
			fragment orderedProjects_item on OrderedProject {
				__typename
				id
				shopifyProductId
				shopifyAdminProductUrl
				sourceType
				sourceImageUrl
				createdAt
				updatedAt
				mediumImageUrl
				instructionManualUrl
				warehouseInstructionManualUrl
				picture {
					...pictureSummary
				}
				shopifyEmailAddress
				user {
					__typename
					id
					...userSummary
				}
			}
			${pictureSummaryFragment}
			${userSummaryFragment}
		`,
	},
	DELETE: {
		name: "deleteOrderedProject",
	},
} as GraphQLResourceDefinition;
