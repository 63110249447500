import { gql } from "@apollo/client";
import { InventoryState } from "./inventory-state.ts";
import fullInventoryStateFragment from "./full-inventory-state-fragment.ts";

export type GetInventoryStateLegacyQuery = {
	readonly inventoryStateLegacy: InventoryState;
};

export type GetInventoryStateLegacyVariables = {
	readonly date?: string;
};

export default gql`
	query getInventoryStateLegacyQuery($date: DateTime) {
		inventoryStateLegacy(date: $date) {
			...fullInventoryState
		}
	}
	${fullInventoryStateFragment}
`;
