import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";
import userSummaryFragment from "~/api/user-summary-fragment.ts";
import pictureSummaryFragment from "~/api/picture-summary-fragment.ts";

export default {
	GET_LIST: {
		name: "savedProjectList",
		useListAndCount: true,
		itemFragment: gql`
			fragment savedProjectsList_item on SavedProject {
				__typename
				id
				updatedAt
				mediumImageUrl
				userIpAddress
				user {
					__typename
					id
					emailAddress
				}
			}
		`,
	},
	// GET_EXPORT: {
	//   name: "savedProjectList",
	//   useListAndCount: true,
	//   itemFragment: gql`
	//     fragment savedProjectsExport_item on SavedProject {
	//       __typename
	//       id
	//       updatedAt
	//       mediumImageUrl
	//       sourceImageUrl
	//       user {
	//         __typename
	//         id
	//         ...fullUserInfoExport_user
	//       }
	//       currentPicture {
	//         ...fullPictureConfigurationExport_pictureConfiguration
	//       }
	//     }
	//     ${fullUserInfoExportFragment}
	//     ${fullPictureConfigurationExportFragment}
	//   `,
	// },
	GET_ONE: {
		name: "savedProjectById",
		itemFragment: gql`
			fragment savedProjects_item on SavedProject {
				__typename
				id
				createdAt
				updatedAt
				userIpAddress
				mediumImageUrl
				sourceImageUrl
				backgroundMaskImageUrl
				enhanceFacesImageUrl
				colorisationImageUrl
				faceBoundingBoxes {
					x
					y
					width
					height
					confidence
				}
				currentPicture {
					...pictureSummary
				}
				user {
					__typename
					id
					...userSummary
				}
			}
			${pictureSummaryFragment}
			${userSummaryFragment}
		`,
	},
	DELETE: {
		name: "deleteSavedProject",
	},
} as GraphQLResourceDefinition;
