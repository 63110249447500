import React, { useState, useCallback } from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	EmailField,
	ShowButton,
	Show,
	SimpleShowLayout,
	Filter,
	TextInput,
	ListActions,
	DateInput,
	DeleteButton,
	Button,
	useNotify,
	FunctionField,
	ListActionsProps,
} from "react-admin";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CheckCircle from "@material-ui/icons/CheckCircle";
import VpnKey from "@material-ui/icons/VpnKey";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import LinkToRelated from "~/components/link-to-related.tsx";
import CountryTextField from "~/components/country-text-field.tsx";
import ExportReportButton from "~/components/export-report-button.tsx";

function UserFilter(props: any) {
	return (
		<Filter {...props}>
			<TextInput source="emailAddress" alwaysOn placeholder="Email address" />
			<DateInput label="From date" source="fromDate" />
			<DateInput label="To date" source="toDate" />
		</Filter>
	);
}

function ChangePasswordButton({ record }: any) {
	const [isOpen, setOpen] = useState(false);
	const onClose = () => {
		setOpen(false);
	};

	const [password, setPassword] = useState("");
	const notify = useNotify();
	const [changePassword, { loading }] = useMutation(
		gql`
			mutation changePasswordMutation($userId: ID!, $password: String!) {
				changeUserPassword(userId: $userId, password: $password)
			}
		`,
		{
			onCompleted() {
				notify("User password changed", "info");
				onClose();
			},
			onError(e) {
				notify(e.message, "error");
			},
		},
	);
	const onConfirm = useCallback(
		async (e) => {
			e.stopPropagation();
			await changePassword({
				variables: {
					userId: record.id,
					password,
				},
			});
		},
		[password, record, changePassword],
	);

	const onClick = useCallback((e) => {
		e.stopPropagation();
	}, []);

	return (
		<>
			<Button
				type="button"
				onClick={() => setOpen(true)}
				label="Change password"
			>
				<VpnKey />
			</Button>
			<Dialog
				open={isOpen}
				onClose={onClose}
				onClick={onClick}
				aria-labelledby="alert-dialog-title"
			>
				<DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
				<DialogContent>
					<div>
						<Input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="New password"
							disabled={loading}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} onClick={onClose} label="Cancel">
						<ErrorOutline />
					</Button>
					<Button
						disabled={loading || password.length === 0}
						onClick={onConfirm}
						label="Confirm"
					>
						<CheckCircle />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function UserListActions(props: ListActionsProps) {
	return (
		<div
			style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
		>
			<ListActions {...props} />
			<ExportReportButton
				mutation={gql`
					mutation startUsersReportMutation {
						createUsersReport
					}
				`}
			/>
		</div>
	);
}

function UserList(props: any) {
	return (
		<List
			{...props}
			filters={<UserFilter />}
			bulkActionButtons={false}
			sort={{ field: "joinedAt", order: "DESC" }}
			pagination={<LoadMorePagination showTotal />}
			actions={<UserListActions />}
			exporter={false}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<DateField source="joinedAt" showTime sortable={false} />
				<TextField source="currentSite" sortable={false} />
				<EmailField source="emailAddress" sortable={false} />
				<TextField source="fullName" label="Name" sortable={false} />
				<LinkToRelated
					pathname="/savedProjects"
					filter={{
						emailAddress: (record) => record.emailAddress,
					}}
				>
					Saved Projects
				</LinkToRelated>
				<ChangePasswordButton />
				<ShowButton />
				<DeleteButton undoable={false} />
			</Datagrid>
		</List>
	);
}

function UserShow(props: any) {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<DateField source="joinedAt" showTime />
				<TextField source="currentSite" />
				<EmailField source="emailAddress" />
				<TextField source="givenName" />
				<TextField source="middleName" />
				<TextField source="familyName" />
				<TextField source="howHeard" />
				<CountryTextField source="country" />
				<TextField source="ipAddress" />
				<CountryTextField source="ipAddressCountry" />
				<DateField source="birthDate" />
				<DateField source="mailchimpLastUpdatedAt" showTime />
				<FunctionField
					label="Mailchimp Sent Tags"
					render={(record: any) => record.mailchimpSentTags?.join(", ")}
				/>
			</SimpleShowLayout>
		</Show>
	);
}

export { UserList, UserShow };
