import { gql } from "@apollo/client";

type UpdatePricingModelMutationVariables = {
	readonly input: {
		readonly numColoursPremiumThreshold: number;
		readonly numColoursPremium: number;
		readonly discountBrackets: {
			readonly areaMetres: number;
			readonly discount: number;
		}[];
		readonly basePlateSizePrices: {
			readonly size: string;
			readonly priceDollars: number;
		}[];
	};
};

const updatePricingModelMutation = gql`
	mutation updatePricingModelMutation(
		$numColoursPremiumThreshold: Int!
		$numColoursPremium: Float!
		$discountBrackets: [UpdatepricingmodelDiscountbrackets!]!
		$basePlateSizePrices: [UpdatepricingmodelBaseplatesizeprices!]!
	) {
		updatePricingModel(
			numColoursPremiumThreshold: $numColoursPremiumThreshold
			numColoursPremium: $numColoursPremium
			discountBrackets: $discountBrackets
			basePlateSizePrices: $basePlateSizePrices
		) {
			numColoursPremiumThreshold
			numColoursPremium
			discountBrackets {
				areaMetres
				discount
			}
			basePlateSizePrices {
				size
				price {
					amount
					currencyCode
				}
			}
		}
	}
`;

export type { UpdatePricingModelMutationVariables };
export default updatePricingModelMutation;
