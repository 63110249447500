import { gql } from "@apollo/client";

const createCostPriceInventoryMutation = gql`
	mutation createCostPriceInventoryMutation(
		$containers: [CreatecostpriceinventoryContainers!]!
		$baseplates: [CreatecostpriceinventoryBaseplates!]!
		$brickSeparators: String
		$smallFlatpackBox: String
		$packaging: String
		$paperA5: String
		$paperA4: String
		$paperLetter: String
		$paperA3: String
	) {
		createCostPriceInventory(
			containers: $containers
			baseplates: $baseplates
			brickSeparators: $brickSeparators
			smallFlatpackBox: $smallFlatpackBox
			packaging: $packaging
			paperA5: $paperA5
			paperA4: $paperA4
			paperLetter: $paperLetter
			paperA3: $paperA3
		) {
			__typename
			id
		}
	}
`;

export default createCostPriceInventoryMutation;
