import { useQuery, QueryHookOptions } from "@apollo/client/react";
import { useNotify } from "react-admin";
import { DateTime } from "luxon";
import getInventoryStateLegacyQuery, {
	GetInventoryStateLegacyQuery,
	GetInventoryStateLegacyVariables,
} from "~/api/get-inventory-state-legacy-query.ts";
import { InventoryState } from "~/api/inventory-state.ts";

function useInventoryLegacyState(
	date?: DateTime,
	options?: Omit<
		QueryHookOptions<
			GetInventoryStateLegacyQuery,
			GetInventoryStateLegacyVariables
		>,
		"variables"
	>,
): { loading: boolean; inventoryState?: InventoryState } {
	const notify = useNotify();
	const { data, loading } = useQuery<
		GetInventoryStateLegacyQuery,
		GetInventoryStateLegacyVariables
	>(getInventoryStateLegacyQuery, {
		variables: { date: date?.toISO() },
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
		...options,
	});
	return { inventoryState: data?.inventoryStateLegacy, loading };
}

export default useInventoryLegacyState;
