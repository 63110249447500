import { gql } from "@apollo/client";

const bulkInventoryScanResource = {
	GET_LIST: {
		name: "bulkInventoryScans",
		useListAndCount: true,
		itemFragment: gql`
			fragment bulkInventoryItemsScan on AdminBulkInventoryScan {
				__typename
				id
				status {
					type
					applyType
				}
				items {
					barcode
					unitsPerScan
					itemType {
						type
						size
						containerId
						colourId
					}
					adjustment
					scanCount
				}
				createdAt
				updatedAt
			}
		`,
	},
	GET_EXPORT: {
		name: "bulkInventoryScans",
		useListAndCount: true,
		itemFragment: gql`
			fragment bulkInventoryItemsScanExport on AdminBulkInventoryScan {
				__typename
				id
				status {
					type
					applyType
				}
				items {
					barcode
					unitsPerScan
					itemType {
						type
						size
						containerId
						colourId
					}
					scanCount
					adjustment
				}
				createdAt
				updatedAt
			}
		`,
	},
	GET_ONE: {
		name: "bulkInventoryScan",
		itemFragment: gql`
			fragment bulkInventoryItemScan on AdminBulkInventoryScan {
				__typename
				id
				status {
					type
					applyType
				}
				items {
					barcode
					unitsPerScan
					itemType {
						type
						size
						containerId
						colourId
					}
					adjustment
					scanCount
				}
				createdAt
				updatedAt
			}
		`,
	},
	CREATE: {
		name: "createBulkInventoryScan",
		argsType: "flat",
		// [CreatebulkinventoryscanItems]!
	},
} as any;

export default bulkInventoryScanResource;
