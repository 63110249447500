import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "premadeKitCodeList",
		useListAndCount: true,
		itemFragment: gql`
			fragment listPremadeKitCodeItem on AdminPremadeKitCode {
				__typename
				id
				code
				generationId
				overrideMaxEmailUsages
				usages {
					email
					name
					numberOfUses
					firstUsedAt
				}
				createdAt
			}
		`,
	},
} as GraphQLResourceDefinition;
