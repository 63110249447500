import { gql } from "@apollo/client";

export default gql`
	query getPricingModelQuery {
		pricingModel {
			numColoursPremiumThreshold
			numColoursPremium
			discountBrackets {
				areaMetres
				discount
			}
			basePlateSizePrices {
				size
				price {
					amount
					currencyCode
				}
			}
		}
	}
`;

export type GetPricingModelQuery = {
	readonly pricingModel: {
		readonly numColoursPremiumThreshold: number;
		readonly numColoursPremium: number;
		readonly discountBrackets: readonly {
			readonly areaMetres: number;
			readonly discount: number;
		}[];
		readonly basePlateSizePrices: readonly {
			readonly size: number;
			readonly price: {
				readonly amount: string;
				readonly currencyCode: string;
			};
		}[];
	};
};
