import React, { ReactNodeArray } from "react";
import BaseModal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

type ModalProps = {
	readonly id: string;
	readonly open: boolean;
	readonly onClose: () => void;
	readonly title: string;
	readonly description: string;
	readonly buttons: ReactNodeArray;
};

function Modal({ id, open, onClose, title, description, buttons }: ModalProps) {
	return (
		<BaseModal
			open={open}
			onClose={onClose}
			aria-labelledby={`${id}-modal-title`}
			aria-describedby={`${id}-modal-description`}
		>
			<Card
				style={{
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					position: "absolute",
					width: 400,
				}}
			>
				<CardContent>
					<Typography
						id="pending-delete-modal-title"
						variant="h4"
						component="h4"
					>
						{title}
					</Typography>
					<Typography id="pending-delete-modal-description" component="p">
						{description}
					</Typography>
				</CardContent>
				<CardActions>{buttons}</CardActions>
			</Card>
		</BaseModal>
	);
}

export default Modal;
