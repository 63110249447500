import React from "react";
import BaseInventoryItemShow from "../inventory-shared/base-inventory-item-show.tsx";
import useInventoryData from "./use-inventory-legacy-data.ts";

function InventoryItemShowLegacy(props: any) {
	const { colours, containers } = useInventoryData();

	return (
		<BaseInventoryItemShow
			{...props}
			colours={colours}
			containers={containers}
		/>
	);
}

export default InventoryItemShowLegacy;
