import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "adminReportList",
		useListAndCount: true,
		itemFragment: gql`
			fragment reportsItem on AdminReport {
				__typename
				id
				type
				status
				createdAt
				fileUrl
			}
		`,
	},
} as GraphQLResourceDefinition;
