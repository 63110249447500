import React from "react";
import { FunctionField, Record } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { formatInventoryItemRoundedValue } from "./format-inventory-item-value.ts";

function InventoryItemValueField(props: any) {
	return (
		<FunctionField
			{...props}
			render={(record?: Record) => {
				if (!record) {
					return null;
				}
				const { source } = props;
				const value = record[source];
				if (value === undefined || value === null) {
					return undefined;
				}
				return (
					<Typography
						variant="body2"
						component="span"
						// className={className}
						// {...sanitizeFieldRestProps(rest)}
					>
						{formatInventoryItemRoundedValue(record, value)}
					</Typography>
				);
			}}
		/>
	);
}

InventoryItemValueField.defaultProps = {
	addLabel: true,
	textAlign: "right",
};

export default InventoryItemValueField;
