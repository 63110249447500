import { gql } from "@apollo/client";

type GetCountAndAddRemovesHasLossQuery = {
	readonly countAndAddRemovesHasLoss: boolean;
};

const getCountAndAddRemovesHasLossQuery = gql`
	query getCountAndAddRemovesHasLossQuery(
		$date: DateTime!
		$containers: [CountandaddremoveshaslossContainers!]!
		$baseplates: [CountandaddremoveshaslossBaseplates!]!
		$brickSeparators: Int
		$smallFlatpackBox: Int
		$packaging: Int
		$paperA5: Int
		$paperA4: Int
		$paperLetter: Int
		$paperA3: Int
	) {
		countAndAddRemovesHasLoss(
			date: $date
			containers: $containers
			baseplates: $baseplates
			brickSeparators: $brickSeparators
			smallFlatpackBox: $smallFlatpackBox
			packaging: $packaging
			paperA5: $paperA5
			paperA4: $paperA4
			paperLetter: $paperLetter
			paperA3: $paperA3
		)
	}
`;

export type { GetCountAndAddRemovesHasLossQuery };
export default getCountAndAddRemovesHasLossQuery;
