import { useMemo } from "react";
import { unknownToString } from "@dhau/lang-extras";
import { useNotify } from "ra-core";
import { useQuery as useApolloQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { Container, PaletteColour } from "./data.ts";

function useInventoryData() {
	const notify = useNotify();
	const { data, loading } = useApolloQuery<{
		readonly containersList: readonly Container[];
		readonly paletteColourList: readonly PaletteColour[];
	}>(
		gql`
			query InventoryDataQuery {
				containersList {
					__typename
					identifier
				}
				paletteColourList {
					id
					colour
					enabled
				}
			}
		`,
		{
			fetchPolicy: "cache-first",
			onError(e) {
				notify(unknownToString(e), "error");
			},
		},
	);

	return useMemo(
		() => ({
			colours: data?.paletteColourList ?? [],
			containers: data?.containersList ?? [],
			loading,
		}),
		[data, loading],
	);
}

export default useInventoryData;
