import React, { useRef, useState } from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
	FunctionField,
	Record,
	required,
	email,
	TextInput,
	Button,
	useRefresh,
	useNotify,
	number,
	minValue,
	NumberInput,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import AddBox from "@material-ui/icons/AddBox";
import Person from "@material-ui/icons/Person";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client/core";
import { Form } from "react-final-form";
import LoadMorePagination from "~/components/load-more-pagination.tsx";

function RemoveUsageButton({ record: { code, usages } }: any) {
	const [isOpen, setOpen] = useState(false);

	const notify = useNotify();
	const refresh = useRefresh();
	const [removeUsage, { loading }] = useMutation(
		gql`
			mutation ($input: RemovePremadeKitCodeUsageInput!) {
				removePremadeKitCodeUsage(input: $input) {
					__typename
					id
					usages {
						email
						name
						numberOfUses
						firstUsedAt
					}
				}
			}
		`,
		{
			onCompleted() {
				notify("Removed usage", "info");
				refresh();
			},
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	if (!usages || usages.length === 0) {
		return null;
	}

	return (
		<>
			<Button type="button" onClick={() => setOpen(true)} label="Remove Email">
				<Person />
			</Button>
			<Dialog
				open={isOpen}
				onClose={() => setOpen(false)}
				onClick={(e) => e.stopPropagation()}
				aria-labelledby="remove-usage-dialog-title"
			>
				<DialogTitle id="remove-usage-dialog-title">Remove Email</DialogTitle>
				<DialogContent>
					<ul>
						{usages.map((u: any) => (
							<li key={u.email}>
								Email: {u.email}, Name: {u.name}, # uses: {u.numberOfUses}{" "}
								<Button
									type="button"
									disabled={loading}
									onClick={() =>
										removeUsage({
											variables: {
												input: {
													code,
													email: u.email,
												},
											},
										})
									}
									label="Remove"
								/>
							</li>
						))}
					</ul>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loading}
						onClick={() => setOpen(false)}
						label="Close"
					>
						<ErrorOutline />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function SetCustomLimitButton({
	record: { code, overrideMaxEmailUsages },
}: any) {
	const [isOpen, setOpen] = useState(false);

	const notify = useNotify();
	const refresh = useRefresh();
	const [setLimit, { loading }] = useMutation(
		gql`
			mutation ($input: SetPremadeKitCodeGenerationMaxUsagesInput!) {
				setPremadeKitCodeGenerationMaxUsages(input: $input) {
					__typename
					id
					usages {
						email
						name
						numberOfUses
						firstUsedAt
					}
				}
			}
		`,
		{
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	const formRef = useRef<HTMLFormElement>(null);
	const onSubmit = async ({ maxUsages }: any) => {
		await setLimit({
			variables: {
				input: {
					code,
					maxUses: parseInt(maxUsages, 10),
				},
			},
		});
		refresh();
		notify("Limit updated", "info");
		setOpen(false);
	};

	return (
		<>
			<Button
				type="button"
				onClick={() => setOpen(true)}
				label="Set Max Usages"
			>
				<AddBox />
			</Button>
			<Dialog
				open={isOpen}
				onClose={() => setOpen(false)}
				onClick={(e) => e.stopPropagation()}
				aria-labelledby="set-limit-dialog-title"
			>
				<DialogTitle id="set-limit-dialog-title">
					Set Custom Max Usages
				</DialogTitle>
				<DialogContent>
					<Form
						initialValues={{ maxUsages: overrideMaxEmailUsages }}
						onSubmit={onSubmit}
						render={({ handleSubmit }) => (
							<form
								ref={formRef}
								onSubmit={handleSubmit}
								style={{ display: "flex", alignItems: "center" }}
							>
								<NumberInput
									source="maxUsages"
									disabled={loading}
									validate={[required(), number(), minValue(0)]}
								/>
							</form>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loading}
						onClick={() => setOpen(false)}
						label="Close"
					>
						<ErrorOutline />
					</Button>
					<Button
						disabled={loading}
						onClick={() => formRef.current?.requestSubmit()}
						label="Update"
					>
						<CheckCircle />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function AddUsageButton({ record: { code } }: any) {
	const [isOpen, setOpen] = useState(false);

	const notify = useNotify();
	const refresh = useRefresh();
	const [addUsage, { loading }] = useMutation(
		gql`
			mutation ($input: AddManualUsageToPremadeKitCodeInput!) {
				addManualUsageToPremadeKitCode(input: $input) {
					__typename
					id
					usages {
						email
						name
						numberOfUses
						firstUsedAt
					}
				}
			}
		`,
		{
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	const formRef = useRef<HTMLFormElement>(null);
	const onSubmit = async ({ name, email }: any) => {
		await addUsage({
			variables: {
				input: {
					code,
					name,
					email,
				},
			},
		});
		notify("Limit updated", "info");
		refresh();
		setOpen(false);
	};

	return (
		<>
			<Button type="button" onClick={() => setOpen(true)} label="Add Usage">
				<PersonAdd />
			</Button>
			<Dialog
				open={isOpen}
				onClose={() => setOpen(false)}
				onClick={(e) => e.stopPropagation()}
				aria-labelledby="add-usage-dialog-title"
			>
				<DialogTitle id="add-usage-dialog-title">Add Usage</DialogTitle>
				<DialogContent>
					<Form
						onSubmit={onSubmit}
						render={({ handleSubmit }) => (
							<form
								ref={formRef}
								onSubmit={handleSubmit}
								style={{ display: "flex", alignItems: "center" }}
							>
								<div>
									<TextInput
										source="name"
										disabled={loading}
										validate={[required()]}
									/>
								</div>
								<div>
									<TextInput
										type="email"
										source="email"
										disabled={loading}
										validate={[required(), email()]}
									/>
								</div>
							</form>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loading}
						onClick={() => setOpen(false)}
						label="Close"
					>
						<ErrorOutline />
					</Button>
					<Button
						disabled={loading}
						onClick={() => formRef.current?.requestSubmit()}
						label="Add"
					>
						<CheckCircle />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function PremadeKitCodeList(props: any) {
	return (
		<List
			{...props}
			title="Premade kit codes"
			pagination={<LoadMorePagination showTotal idField="code" />}
			exporter={false}
			sort={{ field: "createdAt", order: "DESC" }}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="code" sortable={false} />
				{/* <TextField source="generationId" sortable={false} /> */}
				<NumberField
					label="Custom max usages (default: 5)"
					source="overrideMaxEmailUsages"
					sortable={false}
				/>
				<FunctionField
					{...props}
					source="usages"
					render={({ usages }: Record) => {
						if (usages.length === 0) {
							return null;
						}

						return (
							<ul>
								{usages.map((u: any) => (
									<li key={u.email}>
										Email:{" "}
										<a
											href={`mailto:${u.email}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{u.email}
										</a>
										, Name: {u.name}, # uses: {u.numberOfUses}
									</li>
								))}
							</ul>
						);
					}}
				/>
				<DateField source="createdAt" sortable={false} showTime />
				<SetCustomLimitButton />
				<RemoveUsageButton />
				<AddUsageButton />
			</Datagrid>
		</List>
	);
}

export { PremadeKitCodeList };
