import React from "react";
import {
	SimpleForm,
	Edit,
	List,
	EditButton,
	Datagrid,
	TextField,
	TextInput,
	Create,
	required,
	ReferenceInput,
	SelectInput,
} from "react-admin";
import ListThumbnailField from "~/components/list-thumbnail-field.tsx";
import ImageUrlField from "~/components/image-url-field.tsx";
import SignedUploadInput from "~/components/signed-upload-input.tsx";

function PicToBrickList(props: any) {
	return (
		<List
			{...props}
			title="Pics to brick"
			pagination={false}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<TextField source="name" sortable={false} />
				<ListThumbnailField source="thumbnailImageUrl" sortable={false} />
				<EditButton />
			</Datagrid>
		</List>
	);
}

function renderPicToBrickForm() {
	return (
		<SimpleForm>
			<TextInput source="name" validate={[required()]} />
			<ReferenceInput
				label="Category"
				source="categoryId"
				reference="picToBrickCategories"
				validate={[required()]}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<SignedUploadInput
				source="imageFileName"
				directory="pic-to-brick"
				validate={[required()]}
			>
				<ImageUrlField />
			</SignedUploadInput>
			<SignedUploadInput
				source="thumbnailImageFileName"
				directory="pic-to-brick"
				validate={[required()]}
			>
				<ImageUrlField />
			</SignedUploadInput>
		</SimpleForm>
	);
}

function PicToBrickEdit(props: any) {
	return (
		<Edit id="id" {...props}>
			{renderPicToBrickForm()}
		</Edit>
	);
}

function PicToBrickCreate(props: any) {
	return <Create {...props}>{renderPicToBrickForm()}</Create>;
}

export { PicToBrickEdit, PicToBrickCreate, PicToBrickList };
