import React from "react";
import BaseInventoryReport from "../inventory-shared/base-inventory-report.tsx";
import ItemsSoldReportLegacy from "./items-sold-report.tsx";
import OnHandReportLegacy from "./on-hand-report.tsx";
import LossReport from "./loss-report.tsx";
import useInventoryData from "./use-inventory-legacy-data.ts";

function InventoryReportLegacy() {
	const { colours, containers } = useInventoryData();
	return (
		<BaseInventoryReport
			title="Inventory report - legacy"
			onHandReportComponent={OnHandReportLegacy}
			itemsSoldReportComponent={ItemsSoldReportLegacy}
			lossReportComponent={LossReport}
			colours={colours}
			containers={containers}
		/>
	);
}

export default InventoryReportLegacy;
