import React from "react";
import getDamageLossInventoryStateLegacyQuery, {
	GetDamageLossInventoryStateLegacyQuery,
} from "~/api/get-damage-loss-inventory-state-legacy-query.ts";
import { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseLossReport from "../inventory-shared/base-loss-report.tsx";

function LossReport(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	return (
		<BaseLossReport
			{...props}
			query={getDamageLossInventoryStateLegacyQuery}
			transformData={(d: GetDamageLossInventoryStateLegacyQuery) =>
				d.damageLossInventoryStateLegacy
			}
		/>
	);
}

export default LossReport;
