import { gql } from "@apollo/client";

export default gql`
	query availablePresignedMediaUrlQuery(
		$directory: String!
		$preferredFileName: String!
		$contentType: String!
	) {
		availablePresignedMediaUrl(
			directory: $directory
			preferredFileName: $preferredFileName
			contentType: $contentType
		) {
			key
			url
		}
	}
`;

export type AvailablePresignedMediaUrl = {
	readonly availablePresignedMediaUrl: {
		readonly key: string;
		readonly url: string;
	};
};

export type AvailablePresignedMediaUrlVariables = {
	readonly directory: string;
	readonly preferredFileName: string;
	readonly contentType: string;
};
