import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "paletteColourList",
		itemFragment: gql`
			fragment paletteColoursItem on AdminPaletteColour {
				__typename
				id
				colour
				enabled
			}
		`,
	},
	GET_ONE: {
		name: "paletteColourById",
		itemFragment: gql`
			fragment paletteColourItem on AdminPaletteColour {
				__typename
				id
				colour
				enabled
			}
		`,
	},
	CREATE: {
		name: "createPaletteColour",
	},
	UPDATE: {
		name: "editPaletteColour",
	},
} as GraphQLResourceDefinition;
