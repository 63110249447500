import React from "react";
import { useMutation } from "@apollo/client/react";
import { useNotify, SelectInput, required, useRedirect } from "react-admin";
import createAddRemoveInventoryMutation from "~/api/create-add-remove-inventory-mutation.ts";
import { useInventoryBasePlateSizes } from "~/hooks/base-plate-sizes.tsx";
import InventoryForm, {
	InventoryFormProps,
	inventoryFormValuesToApi,
} from "./inventory-form.tsx";
import integer from "./integer.ts";

const reasons = ["Stock order", "Damage/Loss", "Sale/Refund", "Other"];

function AddRemoveForm(
	props: Pick<InventoryFormProps, "colours" | "containers" | "inventoryState">,
) {
	const notify = useNotify();
	const redirect = useRedirect();
	const [createAddRemove, addRemoveResult] = useMutation(
		createAddRemoveInventoryMutation,
		{
			onCompleted() {
				notify("Inventory item added", "info");
				redirect("list", "/inventoryItems");
			},
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	const basePlateSizes = useInventoryBasePlateSizes();
	const onSave = async (sourceVariables: any) => {
		await createAddRemove({
			variables: inventoryFormValuesToApi(basePlateSizes, sourceVariables),
		});
	};

	return (
		<InventoryForm
			actionName="Add/Remove Inventory"
			onSave={onSave}
			saving={addRemoveResult.loading}
			cellStep={1}
			cellValidate={integer()}
			{...props}
		>
			<SelectInput
				source="reason"
				label="Reason"
				validate={required()}
				choices={reasons.map((reason) => ({ id: reason, name: reason }))}
			/>
		</InventoryForm>
	);
}

export default AddRemoveForm;
