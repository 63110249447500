import { gql } from "@apollo/client";

const mutation = gql`
	mutation rejectBulkInventoryScan($id: ID!) {
		rejectBulkInventoryScan(id: $id) {
			__typename
		}
	}
`;

type RejectBulkInventoryScanMutation = unknown;

type RejectBulkInventoryScanMutationVariables = {
	readonly id: string;
};

export type {
	RejectBulkInventoryScanMutation,
	RejectBulkInventoryScanMutationVariables,
};
export default mutation;
