import React from "react";
import {
	List,
	Datagrid,
	TextField,
	Create,
	Edit,
	BooleanField,
	SimpleForm,
	TextInput,
	EditButton,
	NumberInput,
	BooleanInput,
	required,
	regex,
	SaveButton,
	Toolbar,
} from "react-admin";

function PaletteColourList(props: any) {
	return (
		<List
			{...props}
			bulkActionButtons={false}
			pagination={false}
			title="Palette colours"
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<TextField source="colour" sortable={false} />
				<BooleanField source="enabled" sortable={false} />
				<EditButton />
			</Datagrid>
		</List>
	);
}

const validateColour = [
	required(),
	regex(/^#[0-9a-fA-F]{6}$/, "Colour must be in hex format (e.g. #123456)"),
];

function PaletteColourCreate(props: any) {
	return (
		<Create {...props}>
			<SimpleForm>
				<NumberInput
					source="id"
					validate={[
						required(),
						regex(/^#[1-9]{1}[0-9]*?$/, "Id must be an integer"),
					]}
				/>
				<TextInput source="colour" validate={validateColour} />
				<BooleanInput source="enabled" defaultValue={false} />
			</SimpleForm>
		</Create>
	);
}

function EditToolbar(props: any) {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	);
}

function PaletteColourEdit(props: any) {
	return (
		<Edit {...props}>
			<SimpleForm toolbar={<EditToolbar />}>
				<TextField source="id" />
				<TextInput source="colour" validate={validateColour} />
				<BooleanInput source="enabled" />
			</SimpleForm>
		</Edit>
	);
}

export { PaletteColourList, PaletteColourCreate, PaletteColourEdit };
