import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "adminPicToBrickList",
		itemFragment: gql`
			fragment listPicToBrickItem on AdminPicToBrick {
				__typename
				id
				name
				thumbnailImageUrl
			}
		`,
	},
	GET_ONE: {
		name: "adminPicToBrickById",
		itemFragment: gql`
			fragment picToBrickItem on AdminPicToBrick {
				__typename
				id
				name
				imageFileName
				thumbnailImageFileName
				categoryId
			}
		`,
	},
	CREATE: {
		name: "adminCreatePicToBrick",
	},
	UPDATE: {
		name: "adminUpdatePicToBrick",
	},
	DELETE: {
		name: "adminDeletePicToBrick",
	},
} as GraphQLResourceDefinition;
