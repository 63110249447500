import React from "react";
import {
	Title,
	SimpleForm,
	NumberInput,
	TextInput,
	minValue,
	required,
	useNotify,
} from "react-admin";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client/react";
import { pick } from "lodash-es";

function InventorySettings() {
	const notify = useNotify();
	const { data, loading } = useQuery(
		gql`
			query InventorySettingsQuery {
				inventorySettings {
					email
					daysToNotifyBeforeRunOut
					pastDaysUsedForCalculation
				}
			}
		`,
		{
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);
	const inventorySettings = data?.inventorySettings;

	const [updateInventorySettings, { loading: saving }] = useMutation(
		gql`
			mutation updateInventorySettings(
				$email: String!
				$daysToNotifyBeforeRunOut: Int!
				$pastDaysUsedForCalculation: Int!
			) {
				updateInventorySettings(
					email: $email
					daysToNotifyBeforeRunOut: $daysToNotifyBeforeRunOut
					pastDaysUsedForCalculation: $pastDaysUsedForCalculation
				) {
					email
					daysToNotifyBeforeRunOut
					pastDaysUsedForCalculation
				}
			}
		`,
		{
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
			onCompleted() {
				notify("Inventory settings updated", "info");
			},
		},
	);
	const onSubmit = async (variables: any) => {
		await updateInventorySettings({ variables });
	};

	return (
		<>
			<Title title="Inventory settings" />
			{loading && <div>Loading...</div>}
			{inventorySettings && (
				<SimpleForm
					initialValues={pick(
						inventorySettings,
						"email",
						"daysToNotifyBeforeRunOut",
						"pastDaysUsedForCalculation",
					)}
					saving={saving}
					save={onSubmit}
				>
					<TextInput source="email" validate={[required()]} />
					<NumberInput
						source="daysToNotifyBeforeRunOut"
						validate={[required(), minValue(1)]}
					/>
					<NumberInput
						source="pastDaysUsedForCalculation"
						validate={[required(), minValue(1)]}
					/>
				</SimpleForm>
			)}
		</>
	);
}

export default InventorySettings;
