import React from "react";
import getDamageLossInventoryStateQuery, {
	GetDamageLossInventoryStateQuery,
} from "~/api/get-damage-loss-inventory-state-query.ts";
import { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseLossReport from "../inventory-shared/base-loss-report.tsx";

function LossReport(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	return (
		<BaseLossReport
			{...props}
			query={getDamageLossInventoryStateQuery}
			transformData={(d: GetDamageLossInventoryStateQuery) =>
				d.damageLossInventoryState
			}
		/>
	);
}

export default LossReport;
