import React, { memo, ReactNode } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Container, PaletteColour } from "./data.ts";

type ColourContainerTableProps = {
	readonly colours: readonly PaletteColour[];
	readonly containers: readonly Container[];
	readonly renderCell: (
		colour: PaletteColour,
		container: Container,
	) => ReactNode;
};

function ColourContainerTable({
	colours,
	containers,
	renderCell,
}: ColourContainerTableProps) {
	// Note: sticky header works only if the container has a fixed height
	return (
		<TableContainer>
			<Table size="small" style={{ width: "100%" }}>
				<TableHead>
					<TableRow>
						<TableCell>Colours</TableCell>
						{containers.map((container) => (
							<TableCell key={container.identifier}>
								{container.identifier}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{colours.map((c) => (
						<TableRow key={c.id}>
							<TableCell>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{c.id}
									<div
										style={{
											background: c.colour,
											width: 22,
											height: 22,
											marginLeft: 10,
										}}
									/>
								</div>
							</TableCell>
							{containers.map((container) => (
								<TableCell key={container.identifier}>
									{renderCell(c, container)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default memo(ColourContainerTable);
