import { gql } from "@apollo/client";

const query = gql`
	query inventoryStateByDate($date: DateTime!) {
		inventoryState(date: $date) {
			items {
				type {
					type
					containerId
					colourId
					size
				}
				quantity
			}
		}
	}
`;

type InventoryStateByDateQuery = {
	readonly inventoryState: {
		readonly items: {
			readonly type: {
				readonly type: "todo";
				readonly containerId: string | undefined;
				readonly colourId: string | undefined;
				readonly size: number | undefined;
			};
			readonly quantity: number;
		}[];
	};
};

type InventoryStateByDateQueryVariables = {
	readonly date: string;
};

export type { InventoryStateByDateQuery, InventoryStateByDateQueryVariables };
export default query;
