import { gql } from "@apollo/client";

export const fullUserInfoExportFragment = gql`
	fragment fullUserInfoExport_user on User {
		id
		emailAddress
		emailAddress
		givenName
		middleName
		familyName
		country
		howHeard
		birthDate
		joinedAt
	}
`;

export const fullPictureConfigurationExportFragment = gql`
	fragment fullPictureConfigurationExport_pictureConfiguration on PictureConfiguration {
		basePlateSize
		numberOfBasePlates {
			width
			height
		}
		totalNumberOfBasePlates
		brightness
		contrast
		saturation
		totalPrice
	}
`;
