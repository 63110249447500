import React from "react";
import getSaleInventoryStateLegacyQuery, {
	GetSaleInventoryStateLegacyQuery,
} from "~/api/get-sale-inventory-state-legacy-query.ts";
import { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseItemsSoldReport from "../inventory-shared/base-items-sold-report.tsx";

function ItemsSoldReportLegacy(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	return (
		<BaseItemsSoldReport
			query={getSaleInventoryStateLegacyQuery}
			transformData={(data: GetSaleInventoryStateLegacyQuery) =>
				data.saleInventoryStateLegacy
			}
			{...props}
		/>
	);
}

export default ItemsSoldReportLegacy;
