import React, { ChangeEvent, useMemo } from "react";
import { GetApp, Publish } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useForm } from "react-final-form";
import { useInventoryBasePlateSizes } from "~/hooks/base-plate-sizes.tsx";
import { createImportTemplate, importToValues } from "./import-spreadsheet.ts";
import { Container, PaletteColour } from "../inventory-shared/data.ts";

type ImportToolbarProps = {
	readonly colours: readonly PaletteColour[];
	readonly containers: readonly Container[];
};

function ImportToolbar({ colours, containers }: ImportToolbarProps) {
	const form = useForm();
	const basePlateSizes = useInventoryBasePlateSizes();

	const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { files },
		} = e;
		if (!files || files.length === 0) {
			return;
		}

		const file = files[0];
		// eslint-disable-next-line no-param-reassign
		e.target.value = "";
		const values = await importToValues(
			basePlateSizes,
			colours,
			containers,
			file,
		);
		form.batch(() => {
			Object.entries(values).forEach(([name, value]) => {
				form.change(name, value);
			});
		});
	};

	const importTemplate = useMemo(() => {
		if (colours.length === 0 || containers.length === 0) {
			return undefined;
		}

		return createImportTemplate(basePlateSizes, colours, containers);
	}, [basePlateSizes, colours, containers]);

	return (
		<div style={{ display: "flex", justifyContent: "flex-end" }}>
			{importTemplate && (
				<Button
					color="primary"
					variant="text"
					href={`data:text/csv,${importTemplate}`}
					download="inventory-import.csv"
				>
					<GetApp />
					Download import template
				</Button>
			)}
			{colours.length > 0 && containers.length > 0 && (
				<Button component="label" color="primary" variant="text">
					<Publish />
					Import spreadsheet
					<input
						type="file"
						accept="text/csv"
						style={{ display: "none" }}
						onChange={onFileSelect}
					/>
				</Button>
			)}
		</div>
	);
}

export default ImportToolbar;
