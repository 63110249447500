import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	ResourceComponentProps,
	Create,
	SimpleForm,
	useNotify,
	Button,
} from "react-admin";
import {
	List as UiList,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	TableContainer,
	TextField,
	Paper,
	Typography,
	CircularProgress,
	ListItem,
	ListItemText,
} from "@material-ui/core";
import {
	Delete as DeleteIcon,
	PlayArrow as StartScanIcon,
	Stop as StopScanIcon,
} from "@material-ui/icons";
import { Record as RaRecord } from "ra-core";
import { gql, useQuery } from "@apollo/client";
import { Field } from "react-final-form";
import { groupBy, partition } from "lodash-es";
import { findOnlyOrThrow } from "@dhau/lang-extras";
import { stripTypenames } from "~/utils/strip-typenames.ts";
import InventoryItemTypeSummary from "./shared-components.tsx";
import useInventoryData from "../inventory-shared/use-inventory-data.ts";
import errorSoundFile from "./error.mp3";
import successSoundFile from "./success.mp3";
import "./scan-create-components.css";

const successSound = new Audio(successSoundFile);
const errorSound = new Audio(errorSoundFile);

const validBarcodeCharacters = "0123456789";

// const bulkInventoryItemSchema = z.object({
// 	barcode: z.string(),
// 	unitsPerScan: z.number().int().positive(),
// 	itemType: bulkItemTypeSchema,
// });

// const bulkInventoryScanItemSchema = bulkInventoryItemSchema.merge(z.object({
// 	adjustment: z.number().int(),
// 	scanCount: z.number().int().positive(),
// }));

// type BulkItem = z.infer<typeof bulkInventoryItemSchema>;

type ScanItemValue = {
	readonly barcode: string;
	readonly time: number;
};

type ItemValue = {
	readonly scans: readonly ScanItemValue[];
	readonly adjustments: {
		readonly barcode: string;
		readonly amount: string;
	}[];
};

function createAggregateDataFromValue(
	value: ItemValue,
	bulkInventoryItems: any[] | undefined,
) {
	return Object.values(
		Object.entries(groupBy(value.scans, "barcode")).toSorted(
			([, b1], [, b2]) =>
				Math.max(...b1.map((v) => v.time)) - Math.max(...b2.map((v) => v.time)),
		),
	).map(([barcode, items]) => {
		const item = findOnlyOrThrow(
			bulkInventoryItems ?? [],
			(i: any) => i.barcode === barcode,
			`Bulk inventory barcode ${barcode} not found`,
		);
		const adjustment = findOnlyOrThrow(
			value.adjustments,
			(v) => v.barcode === barcode,
			"Adjustment not found",
		).amount;
		const proposedAdjustmentValue = parseInt(adjustment, 10);
		const adjustmentValue = Number.isNaN(proposedAdjustmentValue)
			? 0
			: proposedAdjustmentValue;
		return {
			barcode,
			itemType: item.itemType,
			unitsPerScan: item.unitsPerScan,
			count: items.length,
			adjustment,
			total: item.unitsPerScan * items.length + adjustmentValue,
		};
	});
}

type ItemsFieldProps = {
	readonly bulkInventoryItems: any[] | undefined;
	readonly value: ItemValue;
	readonly onChange: (newValue: ItemValue) => void;
};

function ItemsField({ bulkInventoryItems, value, onChange }: ItemsFieldProps) {
	const {
		colours,
		// containers,
		// loading
	} = useInventoryData();

	const notify = useNotify();
	const scanningEnabled = !!bulkInventoryItems;

	const [scanMode, setScanMode] = useState(false);
	const valueRef = useRef(value);
	valueRef.current = value;

	const onChangeRef = useRef(onChange);
	onChangeRef.current = onChange;

	const tryAndSubmitBarcode = useCallback(
		(newBarcode: string) => {
			if (!bulkInventoryItems?.some((i: any) => i.barcode === newBarcode)) {
				errorSound.pause();
				errorSound.currentTime = 0;
				void errorSound.play();
				notify(`Barcode ${newBarcode} not recognised`, { type: "error" });
				return;
			}

			successSound.pause();
			successSound.currentTime = 0;
			void successSound.play();
			notify(`Barcode ${newBarcode} scanned`, {
				type: "success",
				autoHideDuration: 500,
			});
			const { current: currentValue } = valueRef;
			const [currentAdjustments, otherAdjustments] = partition(
				currentValue.adjustments,
				(a) => a.barcode === newBarcode,
			);

			onChangeRef.current({
				adjustments: [
					...(currentAdjustments.length === 0
						? [{ barcode: newBarcode, amount: "0" }]
						: currentAdjustments),
					...otherAdjustments,
				],
				scans: [
					...currentValue.scans,
					{ time: new Date().getTime(), barcode: newBarcode },
				],
			});
		},
		[bulkInventoryItems, notify, onChangeRef, valueRef],
	);

	useEffect(() => {
		if (!scanMode) {
			return;
		}

		const onUiInteraction = () => {
			setScanMode(false);
		};

		let pendingBarcode = "";
		let submitTimeout: number | undefined = undefined;
		const onKeyDown = (e: KeyboardEvent) => {
			e.preventDefault();
			if (submitTimeout !== undefined) {
				clearTimeout(submitTimeout);
			}
			submitTimeout = window.setTimeout(() => {
				if (pendingBarcode.length === 0) {
					return;
				}

				tryAndSubmitBarcode(pendingBarcode);
				pendingBarcode = "";
			}, 100);

			if (validBarcodeCharacters.includes(e.key)) {
				pendingBarcode += e.key;
			}
		};

		window.addEventListener("keydown", onKeyDown);
		window.addEventListener("click", onUiInteraction);
		return () => {
			if (submitTimeout !== undefined) {
				clearTimeout(submitTimeout);
			}
			window.removeEventListener("click", onUiInteraction);
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [scanMode, tryAndSubmitBarcode]);

	const aggregateData = createAggregateDataFromValue(value, bulkInventoryItems);

	const [manualBarcode, setManualBarcode] = useState("");

	return (
		<div>
			<Typography variant="h5">New inventory scan</Typography>
			<div className="bulk-barcode-entry-container">
				<div>
					{scanMode ? (
						<div className="bulk-barcode-scan-active">
							<Button
								color="secondary"
								variant="contained"
								type="button"
								onClick={() => setScanMode(false)}
								disabled={!scanningEnabled}
								label="Exit scan mode"
								startIcon={<StopScanIcon />}
							/>
							<CircularProgress size="1.5rem" /> Scanning active...
						</div>
					) : (
						<Button
							color="secondary"
							variant="contained"
							type="button"
							onClick={() => setScanMode(true)}
							disabled={!scanningEnabled}
							label="Enter scan mode"
							startIcon={<StartScanIcon />}
						/>
					)}
				</div>
				<div>
					<TextField
						label="Manual entry barcode"
						value={manualBarcode}
						onChange={(e) => setManualBarcode(e.target.value)}
					/>
					<Button
						type="button"
						onClick={() => tryAndSubmitBarcode(manualBarcode)}
						label="Submit"
						disabled={!scanningEnabled}
					/>
				</div>
			</div>
			{value.scans.length === 0 && (
				<div>
					<em>No scans yet</em>
				</div>
			)}
			<div className="bulk-inventory-scan-create-container">
				{aggregateData.length > 0 && (
					<div style={{ flex: 1 }}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Barcode</TableCell>
										<TableCell>Item</TableCell>
										<TableCell>Units per scan</TableCell>
										<TableCell>Count</TableCell>
										<TableCell>Adjustment</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{aggregateData.map((a) => (
										<TableRow key={a.barcode}>
											<TableCell>{a.barcode}</TableCell>
											<TableCell>
												<InventoryItemTypeSummary
													colours={colours}
													itemType={a.itemType}
												/>
											</TableCell>
											<TableCell align="right">{a.unitsPerScan}</TableCell>
											<TableCell align="right">{a.count}</TableCell>
											<TableCell>
												<TextField
													type="number"
													value={a.adjustment}
													onChange={(e) => {
														onChange({
															...value,
															adjustments: value.adjustments.map((adj) => {
																if (adj.barcode === a.barcode) {
																	return {
																		...adj,
																		amount: e.target.value,
																	};
																}
																return adj;
															}),
														});
													}}
													onFocus={() => setScanMode(false)}
												/>
											</TableCell>
											<TableCell align="right">
												{a.total.toLocaleString()}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				)}
				{value.scans.length > 0 && (
					<div className="bulk-scan-history">
						<UiList component="ol">
							{/* Array.from to clone. Something really funky happening with sorting.
				Maybe to do with being a final form value object */}
							{value.scans
								.toSorted((a, b) => b.time - a.time)
								.map((v, i) => {
									const item = findOnlyOrThrow(
										bulkInventoryItems ?? [],
										(b: any) => b.barcode === v.barcode,
									);
									return (
										<ListItem key={v.time}>
											<ListItemText>
												{value.scans.length - i}. [{item.barcode}]{" "}
												{item.unitsPerScan} x{" "}
												<InventoryItemTypeSummary
													colours={colours}
													itemType={item.itemType}
												/>
											</ListItemText>
											<Button
												type="button"
												color="primary"
												variant="text"
												size="small"
												onClick={() => {
													onChange({
														...value,
														scans: value.scans.filter((s) => s.time !== v.time),
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</ListItem>
									);
								})}
						</UiList>
					</div>
				)}
			</div>
		</div>
	);
}

const emptyValue: ItemValue = {
	scans: [],
	adjustments: [],
};

function CreateBulkInventoryScan(props: ResourceComponentProps) {
	const notify = useNotify();
	const { data } = useQuery(
		gql`
			query inventoryScanBulkItems {
				bulkInventoryItems(
					pagination: { perPage: 1000 }
					sort: { field: "createdAt", order: "DESC" }
				) {
					data {
						barcode
						unitsPerScan
						itemType {
							type
							size
							containerId
							colourId
						}
					}
				}
			}
		`,
		{
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);
	const bulkInventoryItems = data?.bulkInventoryItems?.data;

	// Need ref since some memoing or something under the hood
	const transform = ({ items }: RaRecord) => {
		const aggregateData = createAggregateDataFromValue(
			items,
			bulkInventoryItems,
		);
		return {
			items: aggregateData.map((a) => {
				const proposedAdjustmentValue = parseInt(a.adjustment, 10);
				const adjustmentValue = Number.isNaN(proposedAdjustmentValue)
					? 0
					: proposedAdjustmentValue;
				return {
					barcode: a.barcode,
					adjustment: adjustmentValue,
					scanCount: a.count,
					unitsPerScan: a.unitsPerScan,
					itemType: stripTypenames(a.itemType),
				};
			}),
		} as any;
	};
	const transformRef = useRef(transform);
	transformRef.current = transform;

	return (
		<Create
			{...props}
			transform={(values) => {
				return transformRef.current(values);
			}}
		>
			<SimpleForm>
				<Field
					name="items"
					render={({ input: { value: rawValue, onChange } }) => {
						return (
							<ItemsField
								bulkInventoryItems={bulkInventoryItems}
								value={rawValue === "" ? emptyValue : rawValue}
								onChange={onChange}
							/>
						);
					}}
				/>
			</SimpleForm>
		</Create>
	);
}

export { CreateBulkInventoryScan };
