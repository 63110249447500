import React, { ReactNode, createContext, useContext, useMemo } from "react";

type PaperType = "Letter" | "A4" | "A3" | "A5";

function isPaperType(value: string): value is PaperType {
	return ["Letter", "A4", "A3", "A5"].includes(value);
}

type ContextValue = {
	readonly paperTypes: readonly PaperType[];
};

const Context = createContext<ContextValue | undefined>(undefined);

type PaperTypesProviderProps = {
	readonly paperTypes: readonly PaperType[];
	readonly children: ReactNode;
};

function PaperTypesProvider({ paperTypes, children }: PaperTypesProviderProps) {
	const value = useMemo(
		() => ({
			paperTypes,
		}),
		[paperTypes],
	);
	return <Context.Provider value={value}>{children}</Context.Provider>;
}

function usePaperTypes() {
	const paperTypes = useContext(Context);
	if (!paperTypes) {
		throw new Error("No shopify url context");
	}
	return paperTypes.paperTypes;
}

export type { PaperType };
export { isPaperType, PaperTypesProvider, usePaperTypes };
