import React from "react";
import { DateTimeInput } from "react-admin";
import { Form } from "react-final-form";
import { DateTime } from "luxon";
import Button from "@material-ui/core/Button";
import InventoryStateReport, {
	InventoryStateReportProps,
} from "./inventory-state-report.tsx";

type BaseOnHandReportProps = Pick<
	InventoryStateReportProps,
	"colours" | "containers"
> & {
	readonly loading: boolean;
	readonly onValuesChange: (values: { date?: DateTime } | undefined) => void;
	readonly values: { date?: DateTime } | undefined;
	readonly state: InventoryStateReportProps["state"] | undefined;
};

function BaseOnHandReport({
	loading,
	state,
	onValuesChange,
	values,
	...props
}: BaseOnHandReportProps) {
	const onSubmit = ({ date }: { date?: Date }) => {
		onValuesChange({ date: date ? DateTime.fromJSDate(date) : undefined });
	};

	return (
		<div>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						style={{ display: "flex", alignItems: "center" }}
					>
						<DateTimeInput
							source="date"
							helperText="Leave blank for current"
							disabled={loading}
						/>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={loading}
						>
							{loading ? "Generating" : "Generate"}
						</Button>
					</form>
				)}
			/>
			{state && (
				<InventoryStateReport state={state} {...props} baseFilename="on-hand" />
			)}
		</div>
	);
}

export default BaseOnHandReport;
