type GraphQLType = {
	readonly fields: readonly Record<string, any>[];
};

export function getSchemaTypeByName(schema: any, name: string): GraphQLType {
	const query = schema.types.find((f: any) => f.name === name);
	if (!query) {
		throw new Error(`Can't find type name ${name} in schema`);
	}
	return query;
}

export function getRootQueryFieldByName(
	schema: any,
	name: string,
): Record<string, any> {
	const field = schema.queries.find((f: any) => f.name === name);
	if (!field) {
		throw new Error(`Can't find root query field ${name}`);
	}
	return field;
}

export function getMutationByName(
	schema: any,
	name: string,
): Record<string, any> {
	return getRootQueryFieldByName(schema, name);
}
