import React, { useRef } from "react";
import {
	List,
	Datagrid,
	TextField,
	NumberField,
	ResourceComponentProps,
	Create,
	SimpleForm,
	TextInput,
	required,
	minValue,
	NumberInput,
	DeleteButton,
	SelectInput,
	FunctionField,
} from "react-admin";
import { findOnlyOrThrow } from "@dhau/lang-extras";
import { useBasePlateSizes } from "~/hooks/base-plate-sizes.tsx";
import useInventoryData from "../inventory-shared/use-inventory-data.ts";
import InventoryItemTypeSummary from "./shared-components.tsx";

function BulkInventoryItemList(props: ResourceComponentProps) {
	const {
		colours,
		// containers,
		// loading
	} = useInventoryData();
	return (
		<List
			{...props}
			title="Bulk inventory item"
			pagination={false}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="barcode" sortable={false} />
				<FunctionField
					source="itemType"
					sortable={false}
					render={({ itemType }: any) => (
						<InventoryItemTypeSummary colours={colours} itemType={itemType} />
					)}
				/>
				<NumberField source="unitsPerScan" sortable={false} />
				<DeleteButton undoable={false} />
			</Datagrid>
		</List>
	);
}

function CreateBulkInventoryItem(props: ResourceComponentProps) {
	const {
		colours,
		containers,
		// loading
	} = useInventoryData();
	const basePlateSizes = useBasePlateSizes();
	const itemTypeOptions = [
		{
			id: "brickSeparators",
			value: { type: "brickSeparators" },
		},
		{
			id: "smallFlatpackBox",
			value: { type: "smallFlatpackBox" },
		},
		{ id: "packaging", value: { type: "packaging" } },
		{ id: "paperA5", value: { type: "paperA5" } },
		{ id: "paperA4", value: { type: "paperA4" } },
		{ id: "paperLetter", value: { type: "paperLetter" } },
		{ id: "paperA3", value: { type: "paperA3" } },
		...basePlateSizes.map((size) => ({
			id: `baseplate-${size}`,
			value: {
				type: "baseplate",
				size,
			},
		})),
		...colours.flatMap((colour) =>
			containers.map((container) => ({
				id: `container-${container.identifier}-${colour.id}`,
				value: {
					type: "container",
					containerId: container.identifier,
					colourId: colour.id.toString(),
				},
			})),
		),
	];

	// There must be some memoing under the hood
	const transformRef = useRef((v: any) => v);
	transformRef.current = ({ itemType, ...rest }) => ({
		itemType: findOnlyOrThrow(
			itemTypeOptions,
			(r) => r.id === itemType,
			`No item type option with id ${itemType}`,
		).value,
		...rest,
	});
	return (
		<Create {...props} transform={(v) => transformRef.current(v)}>
			<SimpleForm>
				<TextInput
					source="barcode"
					validate={[required()]}
					helperText="If you want to use a barcode scanner, click on the field and scan the barcode"
				/>
				<NumberInput
					source="unitsPerScan"
					validate={[required(), minValue(1)]}
				/>
				<SelectInput
					source="itemType"
					choices={itemTypeOptions}
					optionText={(r) => (
						<InventoryItemTypeSummary colours={colours} itemType={r.value} />
					)}
				/>
			</SimpleForm>
		</Create>
	);
}

export { CreateBulkInventoryItem, BulkInventoryItemList };
