function removeTypenames<T>(item: T): T {
	if (typeof item !== "object" || !item) {
		return item;
	}
	if (Array.isArray(item)) {
		return item.map((i) => removeTypenames(i)) as any;
	}

	return Object.fromEntries(
		Object.entries(item as any)
			.filter(([name]) => name !== "__typename")
			.map(([name, value]) => [name, removeTypenames(value)]),
	) as any;
}

export default removeTypenames;
