function stripTypenames(
	value: Record<string, unknown>,
): Record<string, unknown> /* Omit<T, "__typename"> */ {
	return Object.fromEntries(
		Object.entries(value)
			.filter(([k]) => k !== "__typename")
			.map(([k, v]) => {
				if (v && typeof v === "object") {
					return [k, stripTypenames(v as Record<string, unknown>)];
				}
				return [k, v];
			}),
	);
}

export { stripTypenames };
