import React from "react";
import ItemsSoldReport from "./items-sold-report.tsx";
import OnHandReport from "./on-hand-report.tsx";
import LossReport from "./loss-report.tsx";
import useInventoryData from "../inventory-shared/use-inventory-data.ts";
import BaseInventoryReport from "../inventory-shared/base-inventory-report.tsx";

function InventoryReport() {
	const { colours, containers } = useInventoryData();

	return (
		<BaseInventoryReport
			title="Inventory report"
			onHandReportComponent={OnHandReport}
			itemsSoldReportComponent={ItemsSoldReport}
			lossReportComponent={LossReport}
			colours={colours}
			containers={containers}
		/>
	);
}

export default InventoryReport;
