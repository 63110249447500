import { gql } from "@apollo/client";

export default gql`
	fragment userSummary on UserSummary {
		id
		emailAddress
		givenName
		middleName
		familyName
		country
		howHeard
		birthDate
		joinedAt
	}
`;
