import React from "react";
import { List, Datagrid, TextField, DateField, UrlField } from "react-admin";

function ReportList(props: any) {
	return (
		<List
			{...props}
			pagination={false}
			exporter={false}
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
		>
			<Datagrid>
				<DateField source="createdAt" showTime />
				<TextField source="id" sortable={false} />
				<TextField source="type" sortable={false} />
				<TextField source="status" sortable={false} />
				<UrlField
					source="fileUrl"
					target="_blank"
					rel="noreferrer noopener"
					sortable={false}
				/>
			</Datagrid>
		</List>
	);
}

export { ReportList };
