import React from "react";
import {
	DateField,
	List,
	Datagrid,
	TextField,
	ShowButton,
	FunctionField,
	Record,
} from "react-admin";
import { gql } from "@apollo/client/core";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import { formatInventoryItemFullValue } from "../inventory-shared/format-inventory-item-value.ts";
import InventoryHistoryActions from "../inventory-shared/inventory-history-actions.tsx";

const exportQuery = gql`
	query HistoryExportLegacyQuery {
		inventoryItemsLegacyExport
	}
`;

function InventoryHistoryLegacy(props: any) {
	return (
		<List
			{...props}
			title="Inventory history - legacy"
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
			pagination={<LoadMorePagination showTotal />}
			actions={
				<InventoryHistoryActions
					query={exportQuery}
					getData={(data) => data.inventoryItemsLegacyExport}
					baseFilename="inventory-legacy-history"
				/>
			}
		>
			<Datagrid>
				<TextField source="id" sortable={false} label="Serial" />
				<DateField source="createdAt" sortable={false} showTime />
				<TextField source="reason" sortable={false} />
				<FunctionField
					source="summary"
					sortable={false}
					render={(record?: Record) => {
						if (!record || !record.summary) {
							return <div />;
						}
						return (
							<div>
								{record.summary.largestItems
									.map(
										(item: any) =>
											`${item.label} - ${formatInventoryItemFullValue(
												record,
												item.value,
											)}`,
									)
									.join(", ")}
								{record.summary.totalItems >
									record.summary.largestItems.length && "..."}
							</div>
						);
					}}
				/>
				<ShowButton />
			</Datagrid>
		</List>
	);
}

export default InventoryHistoryLegacy;
