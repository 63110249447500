import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "premadeKitCodeGenerationList",
		useListAndCount: true,
		itemFragment: gql`
			fragment listPremadeKitCodeGenerationItem on AdminPremadeKitCodeGeneration {
				__typename
				id
				numberOfCodes
				createdAt
			}
		`,
	},
	// Needed as part of create response
	GET_ONE: {
		itemFragment: gql`
			fragment premadeKitCodeGenerations_item on AdminPremadeKitCodeGeneration {
				__typename
				id
				numberOfCodes
				createdAt
			}
		`,
	},
	CREATE: {
		name: "createPremadeKitCodeGeneration",
	},
} as GraphQLResourceDefinition;
