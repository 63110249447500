import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

const resource: GraphQLResourceDefinition = {
	GET_LIST: {
		name: "brickArtDesignList",
		useListAndCount: true,
		itemFragment: gql`
			fragment brickArtDesignList_item on BrickArtDesign {
				__typename
				id
				mediumImageUrl
				title
				# shopifyProductId
				createdAt
			}
		`,
	},
	GET_ONE: {
		name: "brickArtDesignById",
		itemFragment: gql`
			fragment brickArtDesigns_item on BrickArtDesign {
				__typename
				id
				mediumImageUrl
				title
				description
				source {
					type
					id
				}
				sourceImageUrl
				shopifyProductId
				shopifyAdminProductUrl
				tags
				createdAt
			}
		`,
	},
	DELETE: {
		name: "deleteBrickArt",
	},
};

export default resource;
