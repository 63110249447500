import React from "react";
import {
	FileField,
	ImageField,
	List,
	Datagrid,
	TextField,
	ShowButton,
	Show,
	DateField,
	SimpleShowLayout,
	UrlField,
	TextInput,
	Filter,
	ListActions,
	DateInput,
	DeleteButton,
	FunctionField,
} from "react-admin";
import { gql } from "@apollo/client";
import UserSummaryField from "~/components/user-summary-field.tsx";
import PictureSummaryField from "~/components/picture-summary-field.tsx";
import ListThumbnailField from "~/components/list-thumbnail-field.tsx";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import createListExporter from "~/utils/create-list-exporter.ts";
import exportMaxResults from "~/utils/export-max-results.ts";
import userSummaryFragment from "~/api/user-summary-fragment.ts";
import ChangeUserButton from "~/components/change-user-button.tsx";

function OrderedProjectFilter(props: any) {
	return (
		<Filter {...props}>
			<TextInput source="id" name="id" alwaysOn placeholder="Id" />
			<TextInput label="User email address" source="emailAddress" alwaysOn />
			<TextInput
				label="Shopify email address"
				source="shopifyEmailAddress"
				alwaysOn
			/>
			<DateInput label="From date" source="fromDate" />
			<DateInput label="To date" source="toDate" />
		</Filter>
	);
}

function ChangeOrderedProjectUserButton({ record }: any) {
	if (record.isArchived) {
		return null;
	}
	return (
		<ChangeUserButton
			record={record}
			mutation={gql`
				mutation changeOrderedProjectUserMutation($id: ID!, $email: String!) {
					changeOrderedProjectUser(id: $id, email: $email) {
						__typename
						id
						user {
							__typename
							id
							...userSummary
						}
					}
				}
				${userSummaryFragment}
			`}
			useCopy={false}
		/>
	);
}

function DeleteIfAllowedButton({ record, ...rest }: any) {
	// Can't archive already archived
	if (record.isArchived) {
		return null;
	}
	// Don't allow archiving of shopify related - want to keep it in sync
	if (record.sourceType !== "shopifyDetached" && record.shopifyOrderNumber) {
		return <em>Shopify managed</em>;
	}
	return <DeleteButton undoable={false} record={record} {...rest} />;
}

function OrderedProjectList(props: any) {
	return (
		<List
			{...props}
			filters={<OrderedProjectFilter />}
			title="Ordered projects"
			bulkActionButtons={false}
			exporter={createListExporter()}
			sort={{ field: "updatedAt", order: "DESC" }}
			pagination={<LoadMorePagination showTotal />}
			actions={<ListActions exportMaxResults={exportMaxResults} />}
		>
			<Datagrid
				rowStyle={(record) => {
					if (!record.isArchived) {
						return {};
					}
					return {
						textDecoration: "line-through",
						backgroundColor: "#c9c9c9",
					};
				}}
			>
				<TextField source="id" sortable={false} />
				<TextField source="user.emailAddress" label="User" sortable={false} />
				<TextField
					source="shopifyEmailAddress"
					label="Shopify email address"
					sortable={false}
				/>
				<FunctionField
					label="Source"
					source="sourceType"
					sortable={false}
					render={({ sourceType, shopifyOrderNumber }: any) => {
						switch (sourceType) {
							case "shopifyDetached": {
								return `Shopify detached: ${shopifyOrderNumber}`;
							}
							case "shopify": {
								return `Shopify: ${shopifyOrderNumber}`;
							}
							case "createdPriorToSourceTracking":
								return "Legacy";
							case "adminSavedProject":
								return "Admin saved project";
							case "adminCartProject":
								return "Admin cart project";
							case "brickArtDesign":
								return "Brick art design";
							default:
								return sourceType;
						}
					}}
				/>
				<DateField source="updatedAt" showTime sortable />
				<ListThumbnailField source="mediumImageUrl" sortable={false} />
				<ChangeOrderedProjectUserButton />
				<ShowButton />
				<DeleteIfAllowedButton />
			</Datagrid>
		</List>
	);
}

function OrderedProjectShow(props: any) {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<TextField source="shopifyProductId" />
				<UrlField
					source="shopifyAdminProductUrl"
					target="_blank"
					rel="noreferrer noopener"
				/>
				<DateField source="createdAt" showTime />
				<DateField source="updatedAt" showTime />
				<FileField
					source="sourceImageUrl"
					title="Source image"
					target="_blank"
					rel="noopener noreferrer"
				/>
				<ImageField source="mediumImageUrl" />
				<FileField
					source="instructionManualUrl"
					title="Customer instruction manual"
				/>
				<FileField
					source="warehouseInstructionManualUrl"
					title="Warehouse instruction manual"
				/>
				<PictureSummaryField label="Picture" source="picture" />
				<TextField
					label="Shopify order email (if relevant)"
					source="shopifyEmailAddress"
				/>
				<UserSummaryField label="User" source="user" />
			</SimpleShowLayout>
		</Show>
	);
}

export { OrderedProjectShow, OrderedProjectList };
