import React from "react";
import {
	SimpleForm,
	Edit,
	List,
	EditButton,
	Datagrid,
	TextField,
	TextInput,
	Create,
	required,
} from "react-admin";

export function PicToBrickCategoryList(props: any) {
	return (
		<List
			{...props}
			title="Pic to brick categories"
			pagination={false}
			exporter={false}
			bulkActionButtons={false}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<TextField source="name" sortable={false} />
				<EditButton />
			</Datagrid>
		</List>
	);
}

export function PicToBrickCategoryEdit(props: any) {
	return (
		<Edit id="id" {...props}>
			<SimpleForm>
				<TextInput source="name" validate={[required()]} />
			</SimpleForm>
		</Edit>
	);
}

export function PicToBrickCategoryCreate(props: any) {
	return (
		<Create {...props}>
			<SimpleForm>
				<TextInput source="name" validate={[required()]} />
			</SimpleForm>
		</Create>
	);
}
