import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";
import pictureSummaryFragment from "~/api/picture-summary-fragment.ts";
import userSummaryFragment from "~/api/user-summary-fragment.ts";
import {
	fullPictureConfigurationExportFragment,
	fullUserInfoExportFragment,
} from "~/utils/export-fragments.ts";

export default {
	GET_LIST: {
		name: "adminCartProjectList",
		useListAndCount: true,
		itemFragment: gql`
			fragment cartProjectsList_item on CartProject {
				__typename
				id
				updatedAt
				mediumImageUrl
				user {
					__typename
					id
					emailAddress
				}
			}
		`,
	},
	GET_EXPORT: {
		name: "adminCartProjectList",
		useListAndCount: true,
		itemFragment: gql`
			fragment cartProjectsExport_item on CartProject {
				__typename
				id
				updatedAt
				mediumImageUrl
				sourceImageUrl
				user {
					__typename
					id
					...fullUserInfoExport_user
				}
				picture {
					...fullPictureConfigurationExport_pictureConfiguration
				}
				shopifyAdminProductUrl
			}
			${fullUserInfoExportFragment}
			${fullPictureConfigurationExportFragment}
		`,
	},
	GET_ONE: {
		name: "adminCartProjectById",
		itemFragment: gql`
			fragment cartProjects_item on CartProject {
				__typename
				id
				shopifyProductId
				shopifyAdminProductUrl
				sourceImageUrl
				updatedAt
				mediumImageUrl
				picture {
					...pictureSummary
				}
				user {
					__typename
					id
					...userSummary
				}
			}
			${pictureSummaryFragment}
			${userSummaryFragment}
		`,
	},
	DELETE: {
		name: "deleteCartProject",
	},
} as GraphQLResourceDefinition;
