import { gql } from "@apollo/client";
import { InventoryState } from "./inventory-state.ts";
import fullInventoryStateFragment from "./full-inventory-state-fragment.ts";

export type GetInventoryStateQuery = {
	readonly inventoryState: InventoryState;
};

export type GetInventoryStateVariables = {
	readonly date?: string;
};

export default gql`
	query getInventoryStateQuery($date: DateTime) {
		inventoryState(date: $date) {
			...fullInventoryState
		}
	}
	${fullInventoryStateFragment}
`;
