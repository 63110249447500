import { gql } from "@apollo/client";

const fullInventoryStateItemFragment = gql`
	fragment fullInventoryStateItem on AdminInventoryStateItem {
		cost
		quantity
		history {
			cost
			quantity
		}
	}
`;

const fullInventoryStateFragment = gql`
	fragment fullInventoryState on AdminInventoryState {
		paperA3 {
			...fullInventoryStateItem
		}
		paperA4 {
			...fullInventoryStateItem
		}
		paperLetter {
			...fullInventoryStateItem
		}
		paperA5 {
			...fullInventoryStateItem
		}
		baseplates {
			size
			cost
			quantity
			history {
				cost
				quantity
			}
		}
		packaging {
			...fullInventoryStateItem
		}
		brickSeparators {
			...fullInventoryStateItem
		}
		smallFlatpackBox {
			...fullInventoryStateItem
		}
		containers {
			colourId
			containerId
			cost
			quantity
			history {
				cost
				quantity
			}
		}
	}
	${fullInventoryStateItemFragment}
`;

export default fullInventoryStateFragment;
