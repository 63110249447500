import { gql } from "@apollo/client";
import { InventoryState } from "./inventory-state.ts";
import fullInventoryStateFragment from "./full-inventory-state-fragment.ts";

export type GetSaleInventoryStateLegacyQuery = {
	readonly saleInventoryStateLegacy: InventoryState;
};

export type GetSaleInventoryStateLegacyVariables = {
	readonly from?: string;
	readonly to?: string;
};

export default gql`
	query getSaleInventoryStateLegacyQuery($from: DateTime, $to: DateTime) {
		saleInventoryStateLegacy(from: $from, to: $to) {
			...fullInventoryState
		}
	}
	${fullInventoryStateFragment}
`;
