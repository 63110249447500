import { gql } from "@apollo/client";

const bulkInventoryItemResource = {
	GET_LIST: {
		name: "bulkInventoryItems",
		useListAndCount: true,
		itemFragment: gql`
			fragment bulkInventoryItemsItem on AdminBulkInventoryItem {
				__typename
				id
				barcode
				unitsPerScan
				itemType {
					type
					size
					containerId
					colourId
				}
				createdAt
				updatedAt
			}
		`,
	},
	GET_ONE: {
		// name: "brickArtDesignById",
		itemFragment: gql`
			fragment bulkInventoryItemsItem on AdminBulkInventoryItem {
				__typename
				id
				barcode
				unitsPerScan
				itemType {
					type
					size
					containerId
					colourId
				}
				createdAt
				updatedAt
			}
		`,
	},
	DELETE: {
		name: "deleteBulkInventoryItem",
	},
	CREATE: {
		name: "createBulkInventoryItem",
		argsType: "flat",
	},
} as any;

export default bulkInventoryItemResource;
