import React from "react";
import { Button, useRedirect, useNotify } from "react-admin";
import { useMutation } from "@apollo/client/react";
import DownloadIcon from "@material-ui/icons/GetApp";
import { DocumentNode } from "@apollo/client/core";

type ExportReportButtonProps = {
	readonly mutation: DocumentNode;
};

function ExportReportButton({ mutation }: ExportReportButtonProps) {
	const notify = useNotify();
	const redirect = useRedirect();
	const [startReport, { loading }] = useMutation(mutation, {
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
		onCompleted() {
			notify("Report has been started", "info");
			redirect("list", "/reports");
		},
	});

	const onExportClick = async () => {
		await startReport();
	};

	return (
		<Button onClick={onExportClick} label="Export" disabled={loading}>
			<DownloadIcon />
		</Button>
	);
}

export default ExportReportButton;
