import React from "react";
import { DateTimeInput, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { Field, useForm, useFormState } from "react-final-form";
import { ResourceComponentPropsWithId, useGetOne, useNotify } from "ra-core";
import { isEqual } from "lodash-es";
import { findOnlyOrThrow } from "@dhau/lang-extras";
import { useMutation, useQuery } from "@apollo/client/react";
import { Delete as RejectIcon, Check as ApproveIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TableBody,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import { stripTypenames } from "~/utils/strip-typenames.ts";
import useInventoryData from "../inventory-shared/use-inventory-data.ts";
import { PaletteColour } from "../inventory-shared/data.ts";
import InventoryItemTypeSummary from "./shared-components.tsx";
import inventoryStateByDateQuery, {
	InventoryStateByDateQuery,
	InventoryStateByDateQueryVariables,
} from "./inventory-state-by-data-query.ts";
import approveBulkInventoryScanMutation, {
	ApproveBulkInventoryScanMutation,
	ApproveBulkInventoryScanMutationVariables,
} from "./approve-bulk-inventory-scan-mutation.ts";
import rejectBulkInventoryScanMutation, {
	RejectBulkInventoryScanMutation,
	RejectBulkInventoryScanMutationVariables,
} from "./reject-bulk-inventory-scan-mutation.ts";

type AdjustmentItem = {
	readonly barcode: string;
	readonly amount: string;
};

type ItemsFieldProps = {
	readonly colours: readonly PaletteColour[];
	readonly scanData: any;
	readonly value: readonly AdjustmentItem[];
	readonly onChange: (newValue: readonly AdjustmentItem[]) => void;
	readonly onHandItems:
		| InventoryStateByDateQuery["inventoryState"]["items"]
		| undefined;
};

function ItemsField({
	colours,
	onHandItems,
	scanData,
	value,
	onChange,
}: ItemsFieldProps) {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Barcode</TableCell>
						<TableCell>Item</TableCell>
						<TableCell>Units per scan</TableCell>
						<TableCell>Count</TableCell>
						<TableCell>Adjustment</TableCell>
						<TableCell>Review adjustment</TableCell>
						<TableCell>Total</TableCell>
						<TableCell>On-hand</TableCell>
						<TableCell>Difference</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{scanData.items.map((i: any) => {
						const adjustmentValue = findOnlyOrThrow(
							value,
							(a) => a.barcode === i.barcode,
							`No adjustment for barcode ${i.barcode} : ${JSON.stringify(value)}`,
						).amount;
						const possibleAdjustmentValueNum = parseFloat(adjustmentValue);
						const adjustmentValueNum = Number.isNaN(possibleAdjustmentValueNum)
							? 0
							: possibleAdjustmentValueNum;
						const total =
							i.scanCount * i.unitsPerScan + i.adjustment + adjustmentValueNum;
						// console.log("TODO: Get on hand");
						const onHandItem = onHandItems?.find((h) =>
							isEqual(stripTypenames(i.itemType), stripTypenames(h.type)),
						);
						return (
							<TableRow key={i.barcode}>
								<TableCell>{i.barcode}</TableCell>
								<TableCell>
									<InventoryItemTypeSummary
										colours={colours}
										itemType={i.itemType}
									/>
								</TableCell>
								<TableCell>{i.unitsPerScan}</TableCell>
								<TableCell>{i.scanCount}</TableCell>
								<TableCell>{i.adjustment}</TableCell>
								<TableCell>
									<TextField
										value={adjustmentValue}
										onChange={(e) =>
											onChange(
												value.map((a) => {
													if (a.barcode === i.barcode) {
														return {
															...a,
															amount: e.target.value,
														};
													}
													return a;
												}),
											)
										}
									/>
								</TableCell>
								<TableCell>{total}</TableCell>
								<TableCell>
									{onHandItems === undefined ? (
										<CircularProgress size="1rem" />
									) : (
										(onHandItem?.quantity?.toLocaleString() ?? 0)
									)}
								</TableCell>
								<TableCell>
									{(total - (onHandItem?.quantity ?? 0)).toLocaleString()}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function ReviewToolbar(props: any) {
	const form = useForm();
	return (
		<Toolbar {...props} style={{ gap: "1rem" }}>
			<SaveButton
				label="Reject"
				name="applyType"
				value="reject"
				variant="text"
				onMouseDown={() => form.change("applyType", "reject")}
				icon={<RejectIcon />}
				style={{ color: "red" }}
			/>
			<SaveButton
				label="Approve as remove"
				name="applyType"
				value="remove"
				onMouseDown={() => form.change("applyType", "remove")}
				icon={<ApproveIcon />}
			/>
			<SaveButton
				label="Approve as count"
				name="applyType"
				value="count"
				onMouseDown={() => form.change("applyType", "count")}
				icon={<ApproveIcon />}
			/>
			<SaveButton
				label="Approve as add"
				name="applyType"
				value="count"
				onMouseDown={() => form.change("applyType", "add")}
				icon={<ApproveIcon />}
			/>
		</Toolbar>
	);
}

type ReviewBulkInventoryScanFieldsProps = {
	readonly scanData: any;
	readonly colours: readonly PaletteColour[];
};

function ReviewBulkInventoryScanFields({
	scanData,
	colours,
	...props
}: ReviewBulkInventoryScanFieldsProps) {
	const {
		values: { applyAt },
	} = useFormState();
	const notify = useNotify();

	const { data: onHandData } = useQuery<
		InventoryStateByDateQuery,
		InventoryStateByDateQueryVariables
	>(inventoryStateByDateQuery, {
		variables: {
			date: applyAt.toISOString(),
		},
		skip: !applyAt,
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
	});
	const onHandItems = onHandData?.inventoryState?.items;

	return (
		<>
			<DateTimeInput {...props} source="applyAt" />
			<Field
				{...props}
				name="adjustments"
				render={({ input: { value, onChange } }) => (
					<ItemsField
						colours={colours}
						value={value}
						onChange={onChange}
						scanData={scanData}
						onHandItems={onHandItems}
					/>
				)}
			/>
		</>
	);
}

function ReviewBulkInventoryScan({ id }: ResourceComponentPropsWithId) {
	if (!id) {
		throw new Error("No scan id");
	}

	const { data: scanData } = useGetOne("bulkInventoryCounts", id);
	const { colours, loading: loadingReferences } = useInventoryData();

	const [approveBulkInventoryScan, { loading: approveSaving }] = useMutation<
		ApproveBulkInventoryScanMutation,
		ApproveBulkInventoryScanMutationVariables
	>(approveBulkInventoryScanMutation, {
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
		onCompleted() {
			notify("Scan accepted", { type: "success" });
			history.push("/bulkInventoryCounts");
		},
	});
	const [rejectBulkInventoryScan, { loading: rejectSaving }] = useMutation<
		RejectBulkInventoryScanMutation,
		RejectBulkInventoryScanMutationVariables
	>(rejectBulkInventoryScanMutation, {
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
		onCompleted() {
			history.push("/bulkInventoryCounts");
			notify("Scan rejected", { type: "success" });
		},
	});
	const history = useHistory();
	const notify = useNotify();

	if (!scanData || loadingReferences || !scanData.items) {
		return <CircularProgress />;
	}

	return (
		<SimpleForm
			toolbar={<ReviewToolbar />}
			save={async ({
				applyAt,
				adjustments,
				applyType,
			}: {
				applyType: "count" | "add" | "remove" | "reject";
				applyAt: Date;
				adjustments: readonly AdjustmentItem[];
			}) => {
				if (applyType === "reject") {
					await rejectBulkInventoryScan({
						variables: {
							id,
						},
					});
					return;
				}

				await approveBulkInventoryScan({
					variables: {
						id,
						applyAt: applyAt.toISOString(),
						adjustments: adjustments.map((a) => {
							const possibleAmount = parseFloat(a.amount);
							return {
								...a,
								amount: Number.isNaN(possibleAmount) ? 0 : possibleAmount,
							};
						}),
						applyType,
					},
				});
			}}
			saving={approveSaving || rejectSaving}
			initialValues={{
				applyType: undefined,
				applyAt: new Date(),
				adjustments: scanData.items.map((i: any) => ({
					barcode: i.barcode,
					amount: 0,
				})),
			}}
		>
			<ReviewBulkInventoryScanFields scanData={scanData} colours={colours} />
		</SimpleForm>
	);
}

export { ReviewBulkInventoryScan };
