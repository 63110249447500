import React from "react";
import { FunctionField, FunctionFieldProps, Record } from "react-admin";
import countryOptions from "~/utils/country-options.ts";

type CountryTextFieldProps = Omit<FunctionFieldProps, "render">;

function CountryTextField(props: CountryTextFieldProps) {
	return (
		<FunctionField
			{...props}
			render={(record?: Record, source?: string) => {
				if (!record || !source) {
					return null;
				}

				const value = record[source];
				if (!value) {
					return null;
				}

				const countryOption = countryOptions.find(
					(o) => o.value.toLowerCase() === value.toLowerCase(),
				);
				if (countryOption) {
					return `${countryOption.label} (${value})`;
				}
				return value;
			}}
		/>
	);
}

CountryTextField.defaultProps = {
	addLabel: true,
};

export default CountryTextField;
