import React from "react";
import getSaleInventoryStateQuery, {
	GetSaleInventoryStateQuery,
} from "~/api/get-sale-inventory-state-query.ts";
import { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseItemsSoldReport from "../inventory-shared/base-items-sold-report.tsx";

function ItemsSoldReport(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	return (
		<BaseItemsSoldReport
			query={getSaleInventoryStateQuery}
			transformData={(data: GetSaleInventoryStateQuery) =>
				data.saleInventoryState
			}
			{...props}
		/>
	);
}

export default ItemsSoldReport;
